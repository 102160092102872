const ROUTES = {
    CWG_SEARCH: "/cwg/search",
    CWG_ENTRY: "/cwg/entry",
    CWG_INDEX: "/cwg/index/$xwgId",
    CWG_MODIFY: "/cwg/modify/$xwgId",

    BROWSE_ADVANCED: "/cwg/browse/advanced",

    COLLECTION: "/cwg-collection/browse/",

    USER_PROFILE: "/users/index/$username",
    USER_COLLECTION: "/users/collection/$username",
    USER_OFFERS: "/users/offers/$username",
    USER_WANTS: "/users/wants/$username",
    USER_NOT_WANT: "/users/notwant/$username",
    USER_MESSAGES: "/users/messages/$username",

    TOP_COLLECTORS: "/users/stats",
    TOP_OFFERS: "/users/stats_offers",

    MESSAGES: "/messages",
    CHANGE_PASSWORD: "/change-password",
    PROFILE_SETTINGS: "/users/profile",
    CWG_OWN: "/cwg/own",
    CWG_DUPLICATES: "/cwg-collection/duplicates",
    CWG_TAGS: "/cwg-collection/tags",
    IMPORT: "/users/import",
    EXPORT: "/users/export",
};

const HTTP = {
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,
    PROCESSING: 102,
    EARLY_HINTS: 103,
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    TEMPORARY_REDIRECT: 307,
    PERMANENT_REDIRECT: 308,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    PAYLOAD_TOO_LARGE: 413,
    URI_TOO_LONG: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    EXPECTATION_FAILED: 417,
    I_M_A_TEAPOT: 418,
    MISDIRECTED_REQUEST: 421,
    UNPROCESSABLE_CONTENT: 422,
    LOCKED: 423,
    FAILED_DEPENDENCY: 424,
    TOO_EARLY: 425,
    UPGRADE_REQUIRED: 426,
    PRECONDITION_REQUIRED: 428,
    TOO_MANY_REQUESTS: 429,
    REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
    UNAVAILABLE_FOR_LEGAL_REASONS: 451,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
};

export {
    ROUTES,
    HTTP
};
