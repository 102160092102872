import { createFileRoute } from '@tanstack/react-router'
import {useTranslation} from "../../utils/helpers";
import {useForm} from "react-hook-form";
import {useCurrentUser} from "../../models/user";
import {useFlash} from "../../components/generic/flash";
import {useAPI} from "../../api/api";
import React, {useCallback} from "react";
import {createShortLivedAccessToken, Unauthorized} from "../../api/shortLivedAccessToken";
import {ErrorMessage, FormButtons} from "../../components/generic/form";
import Account from "../../components/account/account";
import {Button} from "../../components/generic/buttons";
import {FormControl, FormLabel, Input} from "@chakra-ui/react";

export const Route = createFileRoute('/_site/change-password')({
    component: ChangePassword,
});

type ChangePasswordForm = {
    currentPassword: string;
    newPassword: string;
    repeatNewPassword: string;
};

function ChangePassword() {
    const {t} = useTranslation("settings");
    const {t: tf} = useTranslation("forms");

    const {
        register,
        handleSubmit,
        setError,
        watch,
        reset,
        formState: {
            isSubmitting,
            errors
        }
    } = useForm<ChangePasswordForm>();
    const currentUser = useCurrentUser();
    const flash = useFlash();
    const api = useAPI();

    const changePassword = useCallback(async (data: ChangePasswordForm) => {
        try {
            const token = await createShortLivedAccessToken({
                api,
                currentUser,
                password: data.currentPassword
            });

            const res = await api.patch("/api/v2/users/me", {
                "password": data.newPassword
            }, {
                "headers": {
                    "Authorization": "Bearer " + token
                }
            });

            if (res.status === 200) {
                flash.success({
                    description: t("Password has been successfully changed."),
                });
                reset();
            }
        } catch (e) {
            if (e instanceof Unauthorized) {
                setError("currentPassword", {
                    "type": "incorrect",
                    "message": t("Invalid old password.")
                });
            }
        }
    }, [currentUser]);

    return <Account title={t("Change password")}>
        <form onSubmit={handleSubmit(changePassword)}>
            <ErrorMessage errors={errors} name={"root"} />
            <FormControl>
                <FormLabel>{t("Old password")}</FormLabel>
                <Input
                    type="password"
                    disabled={isSubmitting}
                    {...register("currentPassword", {
                        required: tf("Required."),
                    })}
                />
                <ErrorMessage errors={errors} name={"currentPassword"} />
            </FormControl>
            <FormControl>
                <FormLabel>{t("New password")}</FormLabel>
                <Input
                    type="password"
                    disabled={isSubmitting}
                    {...register("newPassword", {
                        required: tf("Required."),
                        validate: (val: string) => {
                            if (val.length < 8) {
                                return t("Password must be at least {{minLength}} characters long.", {minLength: 8});
                            }
                        }
                    })}
                />
                <p className={"description"}>{t("Password must be at least {{minLength}} characters long.", {minLength: 8})}</p>
                <ErrorMessage errors={errors} name={"newPassword"} />
            </FormControl>
            <FormControl>
                <FormLabel>{t("New password again")}</FormLabel>
                <Input
                    type="password"
                    disabled={isSubmitting}
                    {...register("repeatNewPassword", {
                        required: tf("Required."),
                        validate: (value) => value === watch("newPassword") || t("New passwords does not match.")
                    })}
                />
                <ErrorMessage errors={errors} name={"repeatNewPassword"} />
            </FormControl>
            <FormButtons>
                <Button
                    type="submit"
                    disabled={isSubmitting}
                >
                    {t("Change")}
                </Button>
            </FormButtons>
        </form>
    </Account>
}
