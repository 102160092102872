import {CurrentUser, User} from "./user";
import {ExtendedTag, SimpleTag, UserTag} from "./tag";
import {Model} from "./model";

import {TokenScope} from "./tokenScope";
import {ListResponse} from "./response";

type XwgImageType = "primary" | "other" | "private";

type ImageAttribute = "id" | "user" | "name" | "url" | "created" | "type";
type PrefixedImageAttribute = "image.id" | "image.user" | "image.name" | "image.url" | "image.created" | "image.type";

type Image = {
    id: number | "primary";
    user: User;
    name: string;
    url: string;
    type: XwgImageType;
    custom?: boolean;
    created: string;
};

type XwgStatus = {
    collected: boolean;
    offered: boolean;
    wanted: boolean;
    not_wanted: boolean;
    last_collected_date: string;
};

type OfferData = {
    pieces: number;
    comment: string;
};

type XwgOffer = {
    user: User;
} & OfferData;

type XwgCollection = {
    date: string;
    pieces: number;
};

type XwgUserCollection = {
    user: User;
} & XwgCollection;

type XwgExtendedCollection = {
    year: number;
    comment: string;
} & XwgCollection;

type PublishStatus = "new" | "published" | "archived" | "hold";

interface Xwg {
    id?: number;
    name?: string;
    version?: string;
    cat_no?: string;
    images?: Image[];
    link?: string;
    note?: string;
    status?: XwgStatus;
    offered?: XwgOffer[];
    collected?: XwgUserCollection[];
    wanted?: User[];
    not_wanted?: User[];
    collection?: XwgExtendedCollection[];
    tags?: (UserTag | ExtendedTag)[];
    publish_status?: PublishStatus;
    hold?: User | null;
    created?: string;
    created_by?: User | null;
}

interface XwgEntry {
    id?: number;
    name?: string;
    version?: string;
    cat_no?: string;
    link?: string | null;
    note?: string | null;
    tags?: (SimpleTag | string)[];
    backside?: FileList;
    frontside?: FileList;
    publish_status?: PublishStatus;
}

type XwgQueryResponse = ListResponse<XwgWithId>;

class Xwg extends Model<Xwg> {
    get categories(): ExtendedTag[] | null {
        if (!this.tags) {
            return null;
        }

        return (this.tags ?? []).filter(t => (t as ExtendedTag).category !== undefined) as ExtendedTag[];
    }

    get overlay(): boolean {
        return (
            this.categories?.some(t => t.overlay) ||
            this.categories?.every(t => t.overlay === null)
        ) ?? false;
    }

    get isPublished(): boolean {
        return this.publish_status === "published" || this.publish_status === "archived";
    }

    get isArchived(): boolean {
        return this.publish_status === "archived";
    }

    get isUnofficial(): boolean {
        return this.cat_no === null;
    }

    canBeChanged() {
        return this.publish_status === "new" || this.publish_status === "hold";
    }

    canModify(user?: CurrentUser | null) {
        return (this.created_by?.id === user?.id && this.canBeChanged()) || user?.has(TokenScope.CWG_EDIT);
    }

    canRemove(user?: CurrentUser | null) {
        return (
            this.created_by?.id === user?.id &&
            this.canBeChanged() &&
            this.collected?.filter(collection => collection.user.id !== user?.id).length === 0
        ) || user?.has(TokenScope.CWG_EDIT);
    }

    toEntry(): XwgEntry {
        return {
            id: this.id,
            name: this.name,
            version: this.version,
            cat_no: this.cat_no,
            link: this.link,
            note: this.note,
            tags: this.tags?.map(t => {
                const tu = t as UserTag;
                if (tu.user) {
                    return tu.name;
                }

                const tc = t as ExtendedTag;

                return {
                    name: tc.id,
                    category: tc.category.id,
                };
            }),
            publish_status: this.publish_status,
        }
    }
}

class XwgWithId extends Xwg {
    get id(): number {
        if (!this.__model.id) {
            throw new Error("Xwg has no id.");
        }

        return this.__model.id;
    }
}

type HistoryEntry = {
    date: string;
    user: User;
    name?: [string | null, string];
    version?: [string | null, string];
    cat_no?: [string | null, string | null];
    link?: [string | null, string | null];
    note?: [string | null, string | null];
    tags?: [ExtendedTag[] | null, ExtendedTag[]];
    publish_status?: [PublishStatus | null, PublishStatus | null];
    hold: [User | null, User | null];
    images?: [string[] | null, string[] | null];
};

export {
    Xwg,
    XwgWithId,
};

export type {
    XwgEntry,
    Image,
    XwgImageType,
    XwgStatus,
    OfferData,
    XwgOffer,
    XwgUserCollection,
    XwgExtendedCollection,
    XwgQueryResponse,
    PublishStatus,
    HistoryEntry,
    ImageAttribute,
    PrefixedImageAttribute,
}
