import {createFileRoute, Link} from '@tanstack/react-router'
import {useTranslation} from "../../../../../utils/helpers";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../../components/generic/breadcrumb";
import {BreadcrumbItem, BreadcrumbLink} from "@chakra-ui/react";
import {Route as AdminIndexRoute} from "../../index";
import {ReviewQueueNavigation, Route as ReviewQueueRoute} from "./index";
import {XwgFilter} from "../../../../../models/xwgSearchQuery";
import React from "react";

export const Route = createFileRoute('/_site/admin/approving/_queue/all')({
    component: () => {
        const {t} = useTranslation("review");
        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Review queue - everything including holds")}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={ReviewQueueRoute.fullPath}>{t("Review queue")}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>{t("All")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>
            <ReviewQueueNavigation route={Route.fullPath} />
        </>
    },
    beforeLoad: ({context}) => {
        (context.query.filter as XwgFilter).publish_status = ["new", "hold"];
        return {
            query: context.query
        }
    }
})
