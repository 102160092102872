import {UnreadCount, UserBox} from "./userBox";
import React from "react";
import {useCurrentUser} from "../models/user";
import "@fontsource/lato/900.css";
import "@fontsource/lato/700.css";

import cwgImg from "../assets/static/cwg.png";

import accountIcon from "../assets/static/icons/account.svg";
import addIcon from "../assets/static/icons/add.svg";
import boxIcon from "../assets/static/icons/box.svg";
import browseIcon from "../assets/static/icons/browse.svg";
import homeIcon from "../assets/static/icons/home.svg";
import loginIcon from "../assets/static/icons/login.svg";
import rankingsIcon from "../assets/static/icons/rankings.svg";
import searchIcon from "../assets/static/icons/search.svg";
import {Link} from "@tanstack/react-router";
import {Box, BoxProps, chakra, ListItem, ListProps, Tooltip, UnorderedList} from "@chakra-ui/react";
import {useTranslation} from "../utils/helpers";
import {TokenScope} from "../models/tokenScope";
import {Logo} from "./logo";


const MainMenu = React.forwardRef<HTMLUListElement, ListProps>(function (props: ListProps, ref) {
    return <UnorderedList
        {...props}
        ref={ref}
        variant={"main-menu"}
        listStyleType={"none"}
        display="flex"
        justifyContent="stretch"
        m={0}
        p={0}
    />
});

type MenuLinkProps = {
    to: string;
    icon: string;
    label: string;
    params?: Record<string, string>;
}

const MenuLink = (props: MenuLinkProps) => {
    return <ListItem>
        <Link to={props.to} params={props.params}>
            <Tooltip label={props.label}>
                <span className={"icon"}>
                    <img className={"icon"} src={props.icon} alt={props.label}/>
                </span>
            </Tooltip>
            <span className={"label"}>{props.label}</span>
        </Link>
    </ListItem>
}

const PageHeader = function ({unreadCount, ...props}: BoxProps & UnreadCount) {
    const {t} = useTranslation("layout");
    const currentUser = useCurrentUser();

    return <chakra.header
        {...props}
        width={"100%"}
        maxW={"var(--chakra-breakpoints-lg)"}
        ml={"auto"}
        mr={"auto"}
        position={"relative"}
    >
        <Box
            position={"relative"}
            pt={".5em"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <Logo />
            <Box
                flexBasis={{base: "0", lg: "40%"}}
                display={"flex"}
                alignItems={"center"}
            >
                <chakra.img
                    src={cwgImg}
                    alt={"CWG"}
                    height={{base: "3em", lg: "auto"}}
                />
            </Box>
            <Box
                visibility={"hidden"}
                position={"absolute"}
                left={"0"}
                top={"0"}
            >
                <chakra.h1 m={0}>{t("CWG Collection")}</chakra.h1>
                <chakra.p m={0}>{t("Website for cataloging the CWG (Wood Geocoins) collection. CWG catalogue.")}</chakra.p>
            </Box>
        </Box>

        <nav>
            <MainMenu>
                {currentUser ? <>
                    <MenuLink to={"/"} icon={homeIcon} label={t("Overview")}/>
                    <MenuLink to={"/cwg/search"} icon={searchIcon} label={t("Search")}/>
                    <MenuLink to={"/cwg/browse"} icon={browseIcon} label={t("Browse catalogue")} />
                    <MenuLink to={"/cwg-collection/browse"} icon={boxIcon} label={t("My collection")} />
                    {currentUser.has(TokenScope.CWG_CREATE) && <MenuLink to={"/cwg/entry"} icon={addIcon} label={t("Entry")} />}
                    <MenuLink to={"/messages"} icon={accountIcon} label={t("My account")} />
                </> : <>
                    <MenuLink to={"/"} icon={loginIcon} label={t("Login")} />
                    <MenuLink to={"/cwg/browse"} icon={browseIcon} label={t("Catalogue")} />
                    <MenuLink to={"/users/stats"} icon={rankingsIcon} label={t("Best collectors")} />
                </>}
            </MainMenu>
        </nav>

        <UserBox unreadCount={unreadCount} />
    </chakra.header>
}

export {
    PageHeader
};
