import ReactDOM from 'react-dom/client';
import React from 'react';

import {StartClient} from '@tanstack/start';
import {createRouter, emptyContext} from './router';
import i18next from "i18next";
import {I18nextProvider, initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {i18n} from "./i18n";

// @ts-ignore
import resources from 'virtual:i18next-loader';
import pino from "pino";
import {RouterContext} from "./models/routerContext";
import {CurrentUser} from "./models/user";

async function hydrate() {
    await i18next
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
            ...i18n,
            ns: i18n.defaultNS,
            resources,
            detection: {
                order: ["htmlTag"],
                caches: [],
            },
        });

    const router = createRouter();

    router.update({
        context: emptyContext,
        hydrate: ({request, currentUser: {currentUser, ...restOfCurrentUser}, ...dehydrated}) => {
            // @ts-ignore
            globalThis.scripts = dehydrated.scripts;
            // @ts-ignore
            globalThis.links = dehydrated.links;

            router.update({
                context: {
                    request: {
                        ...request,
                        log: pino({
                            mixin: () => ({
                                id: request.grid
                            })
                        }),
                        i18n: i18next
                    },
                    currentUser: {
                        currentUser: currentUser ? new CurrentUser(currentUser) : currentUser,
                        ...restOfCurrentUser
                    },
                    ...dehydrated
                } as RouterContext
            });
        }
    });

    const root = document.getElementById("root");
    if (root) {
        ReactDOM.hydrateRoot(
            root,
            <React.StrictMode>
                <I18nextProvider i18n={i18next}>
                    <StartClient router={router}/>
                </I18nextProvider>
            </React.StrictMode>,
        );
    } else {
        throw new Error("Unable to find root container for the application.");
    }
}

hydrate();
