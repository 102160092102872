import {createFileRoute, Link} from '@tanstack/react-router'
import {Route as LayoutRoute} from "./route";
import {ListResponse} from "../../../../../models/response";
import {Image, XwgWithId} from "../../../../../models/xwg";
import React, {useCallback, useMemo, useState} from "react";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    BreadcrumbItem,
    BreadcrumbLink,
    ButtonGroup,
    Center,
    Checkbox,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tab,
    Table,
    TabList,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tooltip,
    Tr,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import {useTranslation} from "../../../../../utils/helpers";
import {XwgFilter} from "../../../../../models/xwgSearchQuery";
import {Route as XwgIndexRoute} from "../../../cwg/index.$xwgId/index";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../../components/generic/breadcrumb";
import {Route as AdminIndexRoute} from "../../index";
import {Route as XwgModifyRoute} from "../../../cwg/modify.$xwgId";
import {useForm} from "react-hook-form";
import {FormattedDate} from "../../../../../components/generic/date";
import {Button, EditButton} from "../../../../../components/generic/buttons";
import FlatColorIconsImageFile from '~icons/flat-color-icons/image-file';
import {ProfileLink} from "../../../../../components/users/profileLink";
import {User} from "../../../../../models/user";

import ChevronLeftIcon from "~icons/mdi/chevron-left";
import ChevronRightIcon from "~icons/mdi/chevron-right";
import EmojioneV1Eye from '~icons/emojione-v1/eye';
import holdIcon from "../../../../../assets/static/icons/hold.svg";
import archiveIcon from "../../../../../assets/static/icons/archive.svg";
import publishIcon from "../../../../../assets/static/icons/publish.svg";

import Loader from "../../../../../components/generic/loader";
import {useAPI} from "../../../../../api/api";

import {Route as HoldsRoute} from "./holds";
import {Route as AllRoute} from "./all";
import {Dd, Dl, Dt} from "../../../../../components/generic/dl";
import {Trans} from "react-i18next";
import {getImageBadge} from "../../../../../components/cwg/cwgAdditionalImages";
import {HTTP} from "../../../../../constants";
import {CwgCategories} from "../../../../../components/cwg/categories";

export const Route = createFileRoute('/_site/admin/approving/_queue/')({
    component: () => {
        const {t} = useTranslation("review");
        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Review queue")}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>{t("Review queue")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>
            <ReviewQueueNavigation route={Route.fullPath} />
        </>
    },
    beforeLoad: ({context}) => {
        (context.query.filter as XwgFilter).publish_status = ["new"];
        return {
            query: context.query
        }
    }
});

type ReviewQueueBatch = {
    items: {[key: string]: any};
}

type ModalImages = {
    xwg: XwgWithId;
    index: number;
}

export function ReviewQueueNavigation({route}: {route: string}) {
    const {t} = useTranslation("review");

    const tabs = [
        {label: t("New"), route: Route.fullPath},
        {label: t("My holds"), route: HoldsRoute.fullPath},
        {label: t("All"), route: AllRoute.fullPath}
    ];

    return <Tabs index={tabs.findIndex(tab => tab.route == route)}>
        <TabList>
            {tabs.map((tab, idx) => <Tab key={idx} as={Link} to={tab.route}>{tab.label}</Tab>)}
        </TabList>
        <TabPanels>
            <Box p={4}>
                <ReviewQueue />
            </Box>
        </TabPanels>
    </Tabs>;
}

export function ReviewQueue() {
    const {items, count} = LayoutRoute.useLoaderData({
        select: ({items, count}) => ({
            items: items.map(item => item instanceof XwgWithId ? item : new XwgWithId(item)),
            count: count
        })
    }) as ListResponse<XwgWithId>;

    const {t} = useTranslation("review");
    const {t: ti} = useTranslation("cwg_index");
    const {t: to} = useTranslation("cwg_ops");
    const {register, watch} = useForm<ReviewQueueBatch>();
    const api = useAPI();
    const toast = useToast();

    const {isOpen, onOpen, onClose} = useDisclosure();
    const [modalImages, setModalImages] = useState<ModalImages | null>(null);
    const [showLoader, setShowLoader] = useState(true);
    const [processed, setProcessed] = useState<{[key: number]: boolean}>({});

    const getImageFilter = useCallback((image: Image) => {
        if (image.type === "primary") {
            if (image.custom === false) {
                return "grayscale(100%)";
            } else {
                return "hue-rotate(50deg)";
            }
        }

        if (image.type === "other") {
            return "hue-rotate(160deg)";
        }

        return undefined;
    }, []);

    const getImageLabel = useCallback((image: Image) => {
        if (image.type === "primary") {
            if (image.custom === false) {
                return t("This xWG has default primary image.");
            }
            return t("This xWG has custom primary image.");
        }

        if (image.type === "other") {
            return t("Secondary image");
        }

        return t("Private image {{name}} by {{user}}", {
            name: image.name,
            user: image.user?.name ?? "unknown user"
        })
    }, [t]);

    const currentModalImage = modalImages?.xwg.images?.[modalImages.index];

    const reviewTable = useMemo(() => <Table>
            <Thead>
                <Tr>
                    <Th className={"td-shrink"}></Th>
                    <Th>{t("xWG")}</Th>
                    <Th>{t("Created by")}</Th>
                    <Th>{t("Categories")}</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            <Tbody>
                {items.map(xwg => <Tr key={xwg.id} opacity={processed[xwg.id] ? .5 : undefined}>
                    <Td className={"td-shrink"}>
                        <Checkbox {...register(`items.${xwg.id}`)} />
                    </Td>
                    <Td>
                        <Text as={"span"} fontWeight={600}>
                            <Link to={XwgIndexRoute.fullPath} params={{xwgId: xwg.id}}>
                                {xwg.name}
                            </Link>{" "}
                        </Text>
                        <Text as={"span"} fontSize="xs">{ti("(version {{version}})", {version: xwg.version, ns: "cwg_index"})}</Text>

                        {xwg.note && <Text>{ti("Note:")} {xwg.note}</Text>}

                        <HStack
                            mt={1}
                            justifyContent={"space-between"}
                        >
                            <ButtonGroup isAttached size={"xs"} variant={"secondary"}>
                                {xwg.images?.map((image, idx) =>
                                    <Tooltip label={getImageLabel(image)} key={idx}>
                                        <Button
                                            icon={FlatColorIconsImageFile}
                                            filter={getImageFilter(image)}
                                            onClick={() => {
                                                setModalImages({
                                                    xwg: xwg,
                                                    index: idx
                                                });
                                                setShowLoader(true);
                                                onOpen();
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </ButtonGroup>

                            <Text fontSize={"2xs"}>{t("ID {{id}}", {id: xwg.id})}</Text>
                        </HStack>
                    </Td>
                    <Td>
                        <ProfileLink user={xwg.created_by as User} />
                        <Text><FormattedDate date={xwg.created} /></Text>
                    </Td>
                    <Td>
                        <CwgCategories xwg={xwg} />
                    </Td>
                    <Td className={"td-shrink"}>
                        {xwg.hold && <Text>{t("On hold by:")}{" "}<ProfileLink user={xwg.hold} /></Text>}

                        <ButtonGroup size={"md"} variant={"secondary"}>
                            <Button
                                as={Link}
                                to={XwgIndexRoute.fullPath}
                                params={{xwgId: xwg.id}}
                                icon={EmojioneV1Eye}
                            >
                                {t("View")}
                            </Button>

                            <EditButton
                                as={Link}
                                to={XwgModifyRoute.fullPath}
                                params={{xwgId: xwg.id}}
                            />

                            <Button
                                icon={publishIcon}
                                onClick={async () => {
                                    const resp = await api.patch(`/api/v2/xwg/${xwg.id}`, {
                                        publish_status: "published"
                                    }, {
                                        validateStatus: status => status === HTTP.OK || status === HTTP.CONFLICT
                                    });

                                    if (resp.status === HTTP.CONFLICT) {
                                        toast({
                                            title: t(
                                                "xWG {{name}} version {{version}} already exists in given categories.",
                                                {
                                                    name: xwg.name,
                                                    version: xwg.version
                                                }
                                            ),
                                            status: "error",
                                            isClosable: true
                                        });
                                    } else {
                                        toast({
                                            title: t(
                                                "xWG {{name}} version {{version}} has been published.",
                                                {
                                                    name: xwg.name,
                                                    version: xwg.version
                                                }
                                            ),
                                            status: "success",
                                            isClosable: true
                                        });

                                        setProcessed((state) => ({
                                            ...state,
                                            [xwg.id]: true
                                        }));
                                    }
                                }}
                            >
                                {t("Publish")}
                            </Button>

                            <Button
                                icon={archiveIcon}
                                onClick={async () => {
                                    await api.patch(`/api/v2/xwg/${xwg.id}`, {
                                        publish_status: "archived"
                                    });

                                    toast({
                                        title: t(
                                            "xWG {{name}} version {{version}} has been archived.",
                                            {
                                                name: xwg.name,
                                                version: xwg.version
                                            }
                                        ),
                                        status: "success",
                                        isClosable: true
                                    });

                                    setProcessed((state) => ({
                                        ...state,
                                        [xwg.id]: true
                                    }));
                                }}
                            >
                                {t("Archive")}
                            </Button>

                            <Button
                                icon={holdIcon}
                                onClick={async () => {
                                    await api.patch(`/api/v2/xwg/${xwg.id}`, {
                                        publish_status: "hold"
                                    });

                                    toast({
                                        title: t(
                                            "xWG {{name}} version {{version}} has been added to your holds.",
                                            {
                                                name: xwg.name,
                                                version: xwg.version
                                            }
                                        ),
                                        status: "success",
                                        isClosable: true
                                    });

                                    setProcessed((state) => ({
                                        ...state,
                                        [xwg.id]: true
                                    }));
                                }}
                            >
                                {t("Hold")}
                            </Button>
                        </ButtonGroup>
                    </Td>
                </Tr>)}
                {count === 0 && <Tr>
                    <Td colSpan={5}>
                        <Alert status={"success"}>
                            <AlertIcon />
                            <Box>
                                <AlertTitle>{t("Queue is empty")}</AlertTitle>
                                <AlertDescription>{t("Hooray! There are no items in the queue.")}</AlertDescription>
                            </Box>
                        </Alert>
                    </Td>
                </Tr>}
            </Tbody>
            <Tfoot>
                <Tr>
                    <Td colSpan={5}>
                        <HStack justifyContent={"space-between"} gap={8}>
                            {count > 0 ? <HStack gap={8}>
                                <Text fontWeight={"bold"}>{t("Process selected:")}</Text>
                                <ButtonGroup variant={"secondary"} size={"sm"}>
                                    <Button
                                        icon={publishIcon}
                                        onClick={async () => {
                                            const items = watch("items");
                                            const ids = Object.keys(items).filter(id => items[id]);

                                            await api.patch(`/api/v2/xwg`, ids.map(id => ({
                                                id,
                                                publish_status: "published"
                                            })));
                                            setProcessed((state) => ({
                                                ...state,
                                                ...Object.fromEntries(ids.map(id => [id, true]))
                                            }));
                                            toast({
                                                title: t("Selected items have been published."),
                                                status: "success",
                                                isClosable: true
                                            });
                                        }}
                                    >
                                        {t("Publish")}
                                    </Button>
                                    <Button
                                        icon={archiveIcon}
                                        onClick={async () => {
                                            const items = watch("items");
                                            const ids = Object.keys(items).filter(id => items[id]);

                                            await api.patch(`/api/v2/xwg`, ids.map(id => ({
                                                id,
                                                publish_status: "archive"
                                            })));
                                            setProcessed((state) => ({
                                                ...state,
                                                ...Object.fromEntries(ids.map(id => [id, true]))
                                            }));
                                            toast({
                                                title: t("Selected items have been archived."),
                                                status: "success",
                                                isClosable: true
                                            });
                                        }}
                                    >
                                        {t("Archive")}
                                    </Button>
                                    <Button
                                        icon={holdIcon}
                                        onClick={async () => {
                                            const items = watch("items");
                                            const ids = Object.keys(items).filter(id => items[id]);

                                            await api.patch(`/api/v2/xwg`, ids.map(id => ({
                                                id,
                                                publish_status: "hold"
                                            })));
                                            setProcessed((state) => ({
                                                ...state,
                                                ...Object.fromEntries(ids.map(id => [id, true]))
                                            }));
                                            toast({
                                                title: t("Selected items have been put on your hold list."),
                                                status: "success",
                                                isClosable: true
                                            });
                                        }}
                                    >
                                        {t("Hold")}
                                    </Button>
                                </ButtonGroup>
                            </HStack> : <Text></Text>}
                            <Text>{t("{{count}} items in queue.", {count})}</Text>
                        </HStack>
                    </Td>
                </Tr>
            </Tfoot>
        </Table>, [api, items, count, watch("items")]);

    return <>
        {reviewTable}
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}
            closeOnEsc={true}
            closeOnOverlayClick={true}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("Images for {{name}} (version {{version}})", {name: modalImages?.xwg.name, version: modalImages?.xwg.version})}</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Center>
                        <img
                            src={currentModalImage?.url}
                            onLoad={() => setShowLoader(false)}
                            alt={currentModalImage?.name}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "90vh",
                                display: showLoader ? "none" : ""
                            }}
                        />
                        {showLoader && <Loader w={"8em"} m={8} borderWidth={"1em"} />}
                    </Center>
                    {currentModalImage && <Dl mt={4}>
                        <Dt>{to("Image type:")}</Dt>
                        <Dd>{currentModalImage && getImageBadge(currentModalImage, t)}</Dd>
                        {["private", "other"].includes(currentModalImage.type) && <>
                            {currentModalImage?.name && <>
                                <Dt>{to("Name:")}</Dt>
                                <Dd>{currentModalImage.name}</Dd>
                            </>}

                            <Dt>{to("Uploaded by:")}</Dt>
                            <Dd><Trans ns={"cwg_ops"}><ProfileLink user={currentModalImage.user} /> at <FormattedDate date={currentModalImage.created} /></Trans></Dd>
                        </>}
                    </Dl>}
                </ModalBody>

                <ModalFooter justifyContent={"space-between"} gap={4}>
                    <Button
                        isDisabled={(modalImages?.index ?? 0) <= 0}
                        onClick={() => {
                            setModalImages((state) => (state && {
                                ...state,
                                index: state.index - 1
                            }));
                            setShowLoader(true);
                        }}
                        icon={ChevronLeftIcon}
                    />

                    <ButtonGroup size={"xs"} variant={"secondary"} isAttached>
                        {modalImages?.xwg.images?.map((image, idx) => <Button
                            key={idx}
                            filter={getImageFilter(image)}
                            onClick={() => {
                                setModalImages((state) => (state && {
                                    ...state,
                                    index: idx
                                }));
                                setShowLoader(true);
                            }}
                            isActive={modalImages?.index === idx}
                            icon={FlatColorIconsImageFile}
                        />)}
                    </ButtonGroup>

                    <Button
                        isDisabled={(modalImages?.index ?? 0) >= (modalImages?.xwg.images?.length ?? 0) - 1}
                        onClick={() => {
                            setModalImages((state) => (state && {
                                ...state,
                                index: state.index + 1
                            }));
                            setShowLoader(true);
                        }}
                        icon={ChevronRightIcon}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>;
}
