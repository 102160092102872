import {createFileRoute} from '@tanstack/react-router'
import {getAPI, useAPI} from "../../../api/api";
import React, {useCallback, useEffect, useState} from "react";
import {VStack} from "@chakra-ui/react";
import {MessageThreadDetail} from "../../../models/messages";
import {SendMessageForm} from "../../../components/messages/sendMessage";
import {MessagesView} from "../../../components/messages/messageView";

export const Route = createFileRoute('/_site/messages/$threadId')({
    component: () => {
        const {messages: initialThread} = Route.useLoaderData();
        const {threadId} = Route.useParams();

        const api = useAPI();
        const [thread, setThread] = useState<MessageThreadDetail>(initialThread);

        const reloadThread = useCallback(async () => {
            const thread = await api.get<MessageThreadDetail>("/api/v2/messages/{threadId}/", {
                pathParams: {
                    threadId: threadId
                }
            });

            setThread(thread.data);
        }, [setThread, api, threadId]);

        useEffect(() => {
            setThread(initialThread);
        }, [initialThread]);

        return <VStack alignItems={"stretch"} justifyContent={"flex-end"} height={"100%"} overflow={"hidden"}>
            <MessagesView thread={thread} flexGrow={0} />
            {initialThread.send_allowed && <SendMessageForm threadId={threadId} showSubject={false} onMessageSent={reloadThread} />}
        </VStack>
    },
    params: {
        parse: (params) => {
            return {
                threadId: params.threadId
            }
        },
        stringify: (params) => {
            return {
                threadId: params.threadId
            }
        }
    },
    loader: async ({ context, params }) => {
        const api = getAPI(context);
        const messages = await api.get<MessageThreadDetail>("/api/v2/messages/{threadId}/", {
            pathParams: {
                threadId: params.threadId
            }
        });

        return {
            messages: messages.data
        }
    }
});
