import {createFileRoute, Link} from '@tanstack/react-router'
import {getAPI} from "../../../api/api";
import {News} from "../../../models/news";
import React from "react";
import {FormattedDate} from "../../../components/generic/date";
import {HStack} from "@chakra-ui/react";
import {ProfileLink} from "../../../components/users/profileLink";
import {useTranslation} from "../../../utils/helpers";
import Markdown from "react-markdown";
import {Route as NewsArchiveRoute} from "./archive";

export const Route = createFileRoute('/_site/news/index/$id')({
    component: () => {
        const {news} = Route.useLoaderData();
        const {t} = useTranslation("news");

        return <>
            <h2><FormattedDate date={news.date} />{" "}&ndash;{" "}{news.title}</h2>
            <Markdown>{news.text}</Markdown>
            <HStack mt={4} textAlign={"right"} gap={4} justifyContent={"space-between"}>
                <Link to={NewsArchiveRoute.fullPath}>{t("News archive")}</Link>
                <p>{t("Published by:")} <ProfileLink user={news.author} /></p>
            </HStack>
        </>
    },
    params: {
        parse: (params) => {
            return {
                id: parseInt(params.id)
            }
        },
        stringify: (params) => {
            return {
                id: params.id?.toString()
            }
        }
    },
    loader: async ({context, params}) => {
        const api = getAPI(context);
        const resp = await api.get<News>(`/api/v2/news/${params.id}`);
        return {
            news: resp.data
        };
    }
});
