import {XwgWithId} from "../../models/xwg";
import React, {ReactNode} from "react";
import {useCurrentUser} from "../../models/user";
import {useTranslation} from "../../utils/helpers";
import {useNavigate} from "@tanstack/react-router";
import {useAPI} from "../../api/api";
import {useFlash} from "../generic/flash";
import {removeXwg} from "../../api/removeXwg";
import {Route as CwgSearchRoute} from "../../routes/_site/cwg/search/index";
import {TokenScope} from "../../models/tokenScope";
import {Route as CwgModifyRoute} from "../../routes/_site/cwg/modify.$xwgId";
import {useAlert} from "../generic/alert";
import {Button} from "../generic/buttons";
import {ButtonGroup, Flex} from "@chakra-ui/react";
import {Route as XwgAdditionalImagesRoute} from "../../routes/_site/cwg/index.$xwgId/images";
import {Route as XwgJoinRoute} from "../../routes/_site/cwg/index.$xwgId/join";
import {Trans} from "react-i18next";


export type CwgOpsProps = {
    xwg: XwgWithId;
}

export function CwgOps({xwg}: CwgOpsProps) {
    const ops: ReactNode[] = [];

    const currentUser = useCurrentUser();
    const {t} = useTranslation("cwg_ops");

    const navigate = useNavigate();
    const api = useAPI();
    const flash = useFlash();
    const {showConfirm} = useAlert();

    if (xwg.canModify(currentUser)) {
        ops.push(<Button
            key={"modify"}
            onClick={() => {
                navigate({
                    to: CwgModifyRoute.fullPath,
                    params: {xwgId: xwg.id}
                });
            }}
        >{t("Modify")}</Button>);
    }

    if (xwg.canRemove(currentUser)) {
        ops.push(<Button
            key={"remove"}
            onClick={async () => {
                if (await showConfirm(
                    t("Are you sure?"),
                    t("Are you sure you want to remove this xWG? This action cannot be undone.")
                ) === "yes") {
                    await removeXwg({api, xwgId: xwg.id});
                    flash.success({description: t("xWG has been removed.")});
                    await navigate({to: CwgSearchRoute.fullPath});
                }
            }}
        >{t("Remove")}</Button>);
    }

    if (currentUser?.has(TokenScope.ADMIN_JOIN)) {
        ops.push(<Button
            key={"join"}
            onClick={async () => {
                await navigate({
                    to: XwgJoinRoute.fullPath,
                    params: {xwgId: xwg.id},
                });
            }}
        >
            <Trans ns={"cwg_ops"}>Join to&hellip;</Trans>
        </Button>);
    }

    if (currentUser?.has(TokenScope.CWG_IMAGES) || (currentUser && xwg.publish_status !== "published" && xwg.publish_status !== "archived")) {
        ops.push(<Button
            key={"additional-pictures"}
            onClick={async () => {
                await navigate({
                    to: XwgAdditionalImagesRoute.fullPath,
                    params: {xwgId: xwg.id}
                })
            }}
        >
            {t("Images")}
        </Button>);
    }

    if (ops.length) {
        return <Flex
            justifyContent={"center"}
        >
            <ButtonGroup
                variant={"secondary"}
                size={"xs"}
                isAttached
                mt={4}
                mb={4}
            >
                {ops}
            </ButtonGroup>
        </Flex>
    } else {
        return null;
    }
}
