import React from "react";
import {useTranslation} from "../../utils/helpers";
import {Outlet, useMatch, useMatches} from "@tanstack/react-router";
import {TabPanels, Tabs} from "@chakra-ui/react";
import {CollapsibleTabList} from "../generic/tabs";
import {findInMatchesData} from "../../utils/findInMatchesData";
import {ExtendedTagInCategory, TagCategory} from "../../models/tag";

export function CwgCataloguePrimaryTabs() {
    const {t} = useTranslation("browse");

    const matches = useMatches();
    const match = useMatch({strict: false});

    const primaryCategory = findInMatchesData<TagCategory | undefined>(matches, "primaryCategory");
    const primaryCategoryTag = findInMatchesData<ExtendedTagInCategory | undefined>(matches, "primaryCategoryTag");

    let closestMatch = null;

    // This is a little ugly, but we need secondary match, not the one rendering primary tabs.
    for (const oneMatch of matches) {
        if (oneMatch.routeId == match.routeId) {
            closestMatch = oneMatch;
        } else if (closestMatch) {
            // The route right after the primary tabs should contain secondary tabs, which we want to keep.
            closestMatch = oneMatch;
            break;
        }
    }

    if (!closestMatch) {
        closestMatch = match;
    }

    return <>
        <Tabs index={primaryCategory?.tags.findIndex(tag => tag.id == primaryCategoryTag?.id)}>
            <CollapsibleTabList tabs={
                primaryCategory?.tags.map(tag => ({
                    to: closestMatch.fullPath,
                    params: Object.assign({}, match, {primaryCategoryName: tag.id}),
                    label: tag.name,
                    tooltip: t("{{count}} items", {count: tag.count})
                })) ?? []
            } />
            <TabPanels>
                <Outlet />
            </TabPanels>
        </Tabs>
    </>
}
