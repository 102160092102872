import {ExtendedTagInCategory, TagCategory} from "../../models/tag";
import {FirstLevelParams, firstLevelRedirect, UpToFirstLevelContext} from "./first";
import {NotFoundError} from "../errors";
import {AnyRoute, redirect} from "@tanstack/react-router";
import {RedirectWithParams} from "./index";
import {CwgCataloguePrimaryTabs} from "../../components/cwg/cwgCataloguePrimaryTabs";
import {ThirdLevelParams} from "./third";
import {ErrorComponent} from "../../components/error";
import {TagInCategoryFilter} from "../../models/xwgSearchQuery";

export type SecondLevelContext = {
    primaryCategoryTag: ExtendedTagInCategory;
    secondaryCategory: TagCategory;
};

export type SecondLevelParams = {
    primaryCategoryName: string;
} & FirstLevelParams;

export type UpToSecondLevelContext = SecondLevelContext & UpToFirstLevelContext;

export function decideSecondaryCategory(props: UpToFirstLevelContext): TagCategory {
    let secondaryCategory = null;
    switch (props.primaryCategory.id) {
        case "country":
            secondaryCategory = props.tagCategories.find(tagCategory => tagCategory.id === "type");
            break;

        case "origin":
            secondaryCategory = props.tagCategories.find(tagCategory => tagCategory.id === "country");
            break;

        case "type":
            secondaryCategory = props.tagCategories.find(tagCategory => tagCategory.id === "origin");
            break;
    }

    if (!secondaryCategory) {
        console.log("Secondary category not found for primary category", props.primaryCategory.id);
        throw new NotFoundError();
    }

    return secondaryCategory;
}

export function getThirdLevelParams(params: SecondLevelParams, context: UpToSecondLevelContext): ThirdLevelParams {
    return {
        ...params,
        secondaryCategoryName: context.secondaryCategory.tags[0].id
    }
}

export async function secondLevelRedirect(to: AnyRoute, context: UpToSecondLevelContext): Promise<RedirectWithParams<ThirdLevelParams>> {
    const {params} = await firstLevelRedirect(to, context);

    return {
        to: to.fullPath,
        params: getThirdLevelParams(params, context),
        replace: true
    }
}

export function secondLevelIndexRoute(thirdLevelRoute: AnyRoute) {
    return {
        beforeLoad: async ({context}: {context: UpToSecondLevelContext}) => {
            throw redirect(await secondLevelRedirect(thirdLevelRoute, context));
        }
    }
}

export async function secondLevelBeforeLoad({context, params}: {context: UpToFirstLevelContext, params: SecondLevelParams}): Promise<SecondLevelContext> {
    if (!context.filter.tags) {
        context.filter.tags = [];
    }

    const primaryCategoryTag = context.primaryCategory.tags.find(tag => tag.id === params.primaryCategoryName);
    if (!primaryCategoryTag) {
        console.error(`Primary category tag ${params.primaryCategoryName} not found in ${context.primaryCategory.id} tags ${context.primaryCategory.tags.map(tag => tag.id)}`);
        throw new NotFoundError();
    }

    const existingFilter = context.filter.tags.find(tag => typeof(tag) === "object" && tag.category === context.primaryCategory.id);
    if (existingFilter) {
        (existingFilter as TagInCategoryFilter).name = primaryCategoryTag.id;
    } else {
        context.filter.tags.push({
            category: context.primaryCategory.id,
            name: primaryCategoryTag.id
        });
    }

    const secondLevelContext = {
        primaryCategoryTag,
    };

    const secondaryCategory = decideSecondaryCategory({
        ...context,
        ...secondLevelContext
    });

    return {
        ...secondLevelContext,
        secondaryCategory,
    }
}

export function secondLevelLayoutRoute() {
    return {
        errorComponent: ErrorComponent,
        component: CwgCataloguePrimaryTabs,
        beforeLoad: secondLevelBeforeLoad,
        loader: ({context}: {context: UpToSecondLevelContext}) => ({
            primaryCategoryTag: context.primaryCategoryTag,
            secondaryCategory: context.secondaryCategory
        })
    }
}
