import {createFileRoute, Link, useRouter} from '@tanstack/react-router'
import {NotFoundError} from "../../../utils/errors";
import React from "react";
import {useTranslation} from "../../../utils/helpers";
import {getAPI, useAPI} from "../../../api/api";
import CwgForm from "../../../components/cwg/cwgForm";
import {HTTP} from "../../../constants";
import {XwgEntry, XwgWithId} from "../../../models/xwg";
import {diagToForm} from "../../../utils/diagToForm";
import {listXwg} from "../../../api/listXwg";
import {getTagCategories} from "../../../api/getTagCategories";
import {getFixedT} from "../../../utils/getFixedT";
import {combineTitle} from "../../../utils/combineTitle";
import {Forbidden, ValidationError} from "../../../utils/errors";
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../components/generic/breadcrumb";
import {BreadcrumbItem, BreadcrumbLink} from "@chakra-ui/react";
import {Route as XwgIndexRoute} from "./index.$xwgId/index";

export const Route = createFileRoute('/_site/cwg/modify/$xwgId')({
    params: {
        parse: (params: Record<string, string>) => {
            if (!params.xwgId) {
                throw new NotFoundError();
            }

            const xwgId = Number.parseInt(params.xwgId);
            if (Number.isNaN(xwgId)) {
                throw new NotFoundError();
            }

            return {
                xwgId
            };
        },
        stringify: (params) => {
            return {
                xwgId: params.xwgId?.toString()
            };
        },
    },
    meta: ({matches, loaderData}) => {
        if (!loaderData) {
            return [];
        }

        const {xwg} = loaderData;
        const t = getFixedT("cwg_ops");

        return combineTitle(matches, t("Modify xWG {{name}} (version {{version}})", {name: xwg.name, version: xwg.version}));
    },
    component: ModifyCwg,
    loader: async ({context, params: {xwgId}}) => {
        const api = getAPI(context);

        const xwgs = await listXwg({
            api,
            query: {
                filter: {
                    ids: [xwgId],
                    publish_status: null,
                },
                display: {
                    attributes: ["id", "name", "cat_no", "version", "note", "link", "tags"]
                }
            }
        });

        if (!xwgs.items.length) {
            throw new NotFoundError("Specified xWG was not found", xwgs.grid);
        }

        const xwg = xwgs.items[0] as XwgWithId;

        if (!xwg.canModify(context.currentUser.currentUser)) {
            throw new Forbidden();
        }

        return {
            tagCategories: await getTagCategories({api}),
            xwg
        }
    }
})


function ModifyCwg() {
    const {t} = useTranslation("cwg_ops");

    const api = useAPI();
    const {tagCategories, xwg: xwgPlain} = Route.useLoaderData();

    const xwg = new XwgWithId(xwgPlain);

    const title = t(
        xwg.version != "1"
            ? "Modify xWG {{name}} (version {{version}})"
            : "Modify xWG {{name}}",
        {
            name: xwg.name,
            version: xwg.version
        }
    );

    const navigate = Route.useNavigate();
    const router = useRouter();

    return <>
        <HeaderWithBreadcrumb>
            <h2>{title}</h2>
            <Breadcrumb>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={XwgIndexRoute.fullPath} params={{xwgId: xwg.id}}>{xwg.name}</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink as={Link} to={Route.fullPath} params={{xwgId: xwg.id}}>{t("Modify")}</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </HeaderWithBreadcrumb>

        <CwgForm
            tagCategories={tagCategories}
            xwg={xwg.toEntry()}
            showImages={false}
            onSubmit={async (xwg, setError) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, publish_status, ...data } = xwg;

                const resp = await api.patch(
                    "/api/v2/xwg/{id}",
                    data,
                    {
                        pathParams: {
                            id: id as number
                        },
                        validateStatus: (status: number) => status === HTTP.OK || status === HTTP.UNPROCESSABLE_CONTENT || status == HTTP.CONFLICT
                    }
                );

                if (resp.status !== HTTP.OK) {
                    const diag = ValidationError.payloadToDiagnostic(resp.data);
                    diagToForm<XwgEntry>(diag, setError, {stripPrefix: ["body"]});
                } else {
                    await router.invalidate();
                    await navigate({
                        to: XwgIndexRoute.fullPath,
                        params: {
                            xwgId: id
                        }
                    });
                }
            }}
        />
    </>
}
