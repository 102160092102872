import {createFileRoute, Link} from '@tanstack/react-router'
import {getAPI} from "../../api/api";
import {NotFoundError} from "../../utils/notFoundError";
import {useCurrentUser} from "../../models/user";
import {Route as IndexRoute} from "./index";
import {Error} from "../../components/error";
import React from "react";

export const Route = createFileRoute('/_site/verify-email')({
    component: () => {
        const { verified } = Route.useLoaderData();
        const cu = useCurrentUser();

        return <>
            <h2>Verify Email</h2>
            {verified
                ? <>
                    <p>Your email has been verified.</p>
                    {!cu && <p>You can now <Link to={IndexRoute.fullPath}>Log in</Link></p>}
                </>
                : <>
                    <Error><p>Your email has not been verified.</p></Error>
                </>}
        </>
    },
    validateSearch: (search) => {
        if (!search.token) {
            throw new NotFoundError();
        }

        return search as {
            token: string;
        };
    },
    loaderDeps: ({search: {token}}) => ({token}),
    loader: async ({context, deps: { token }}) => {
        const api = getAPI(context);
        const response = await api.post(
            "/api/v2/users/me/emails/verify",
            {},
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        );

        if (~~(response.status / 100) === 2) {
            return {
                verified: true
            }
        } else {
            return {
                verified: false
            }
        }
    }
});
