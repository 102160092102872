import {createFileRoute, Link, Outlet} from '@tanstack/react-router'

import React from "react";
import {useTranslation} from "../../../../../utils/helpers";

import {zeroLevelBeforeLoad} from "../../../../../utils/collectionListing";
import {PublicProfile} from "../../../../../components/users/publicProfile";
import {loadUser} from "../route";
import {Button} from "@chakra-ui/react";
import {Route as RoutePrimarySorting} from "./$primarySorting/index";
import {Route as IndexRoute} from "./index";
import {zeroLevelRouteLoader} from "../../../../../utils/collectionListing/zero";

export const Route = createFileRoute('/_site/users/_public_profile/offers/$username')({
    component: CwgBrowseHeader,
    beforeLoad: async ({context, params}) => {
        return {
            ...(await zeroLevelBeforeLoad(context, {
                offered: [params.username]
            })),
            isAdvanced: false
        }
    },
    loader: async ({context, params}) => ({
        ...await loadUser({context, params}),
        ...await zeroLevelRouteLoader({context})
    }),
});

function CwgBrowseHeader() {
    const {t} = useTranslation("profile");
    const {t: tb} = useTranslation("browse");
    const {user, tagCategories} = Route.useLoaderData();

    return <PublicProfile
        selected={IndexRoute.fullPath}
        title={t("{{user}} - xWGs for exchange", {user: user?.name})}
        toolbarTitle={tb("Primary sorting:")}
        toolbarButtons={[
            ...tagCategories.map(category =>
                <Button
                    size={"sm"}
                    key={category.id}
                    as={Link}
                    to={RoutePrimarySorting.fullPath}
                    params={{
                        primarySorting: `by-${category.id}`
                    }}
                >
                    {category.name}
                </Button>
            ),
        ]}
        user={user}
    >
        <Outlet />
    </PublicProfile>
}
