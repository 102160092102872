import {OfferData} from "../../models/xwg";
import {Controller, FormProvider, useForm} from "react-hook-form";
import React, {useCallback, useEffect} from "react";
import {ErrorMessage, FormButtons} from "../generic/form";
import {Button} from "../generic/buttons";
import {useTranslation} from "../../utils/helpers";
import {useAPI} from "../../api/api";
import {updateOffer} from "../../api/updateOffer";
import {
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Textarea,
    FormControl, FormLabel, FormHelperText
} from "@chakra-ui/react";

type OfferFormProps = {
    xwgId: number;
    value?: OfferData;
    disabled?: boolean;
    errors?: Record<string, string>;
    onOfferChange?: (data: OfferData) => void;
}

export const OfferForm = (
    {
        xwgId,
        value,
        onOfferChange,
        disabled,
        errors: submitErrors,
    }: OfferFormProps
) => {
    const {t} = useTranslation("cwg_index");

    const methods = useForm<OfferData>({
        defaultValues: value ?? {
            pieces: 0,
            comment: ""
        },
        criteriaMode: "all",
        disabled: disabled ?? false,
    });
    const { register, handleSubmit, formState: { errors }, setError, reset } = methods;

    useEffect(() => {
        if (submitErrors) {
            Object.keys(submitErrors).forEach(key => {
                setError(key as keyof OfferData, {
                    type: "backend",
                    message: submitErrors[key as keyof OfferData]
                });
            });
        }
    }, [submitErrors]);

    useEffect(() => {
        reset(value ?? {pieces: 0, comment: ""});
    }, [value]);

    const client = useAPI();

    const submitCb = useCallback(async (data: OfferData) => {
        const offerData = await updateOffer({
            api: client,
            xwgId: xwgId,
            data: data
        });
        onOfferChange?.(offerData);
    }, [xwgId, client]);

    return (
        <form onSubmit={handleSubmit(submitCb)}>
            <main>
                <FormProvider {...methods}>
                    <FormControl>
                        <FormLabel>{t("Number of pieces to offer")}:</FormLabel>
                        <Controller
                            name={"pieces"}
                            render={({ field }) => (
                                <NumberInput w={"6em"} {...field} min={0}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            )}
                            rules={{
                                min: {
                                    value: 0,
                                    message: t("Number of pieces must be greater than or equal to 0.")
                                },
                                required: t("Number of pieces is required.")
                            }}
                        />
                        <ErrorMessage errors={errors} name={"pieces"} className={"inline"} />
                        <FormHelperText>{t("By setting this number to higher value than 0, your xWG will be made available for exchange.")}</FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel>{t("Note")}:</FormLabel>
                        <Textarea {...register("comment")} />
                        <ErrorMessage errors={errors} name={"comment"} />
                        <FormHelperText>{t("Optional comment, that will be displayed together with your offer.")}</FormHelperText>
                    </FormControl>
                </FormProvider>
            </main>
            <FormButtons>
                <Button type={"submit"}>{t("Save")}</Button>
            </FormButtons>
        </form>
    );
}
