import { createFileRoute } from '@tanstack/react-router'
import {getAPI} from "../../../../../api/api";
import {UserTag} from "../../../../../models/tag";

export const Route = createFileRoute('/_site/cwg/browse/advanced')({
    loader: async ({context}) => {
        const api = getAPI(context);

        return {
            userTags: (await api.get<UserTag[]>("/api/v2/tags/user/me")).data,
            isAdvanced: true,
        }
    }
});
