import {HistoryEntry} from "../../models/xwg";
import {Box, Divider, Heading, List, ListItem, Stack, StackDivider, Text, VStack} from "@chakra-ui/react";
import {FormattedDateTime} from "../generic/date";
import {ProfileLink} from "../users/profileLink";
import React, {ReactElement} from "react";
import {useTranslation} from "../../utils/helpers";

function HistoryItem<
    I extends Exclude<keyof HistoryEntry, "date" | "user">,
    V = Exclude<Exclude<HistoryEntry[I], undefined>[0] | Exclude<HistoryEntry[I], undefined>[1], null | undefined>,
>({name, entry, item, valueFormatter}: {
    name: string,
    entry: HistoryEntry,
    item: I,
    valueFormatter?: (v: V) => string | ReactElement
}) {
    const {t} = useTranslation("cwg_index");

    if (!valueFormatter) {
        valueFormatter = (v: V) => v as string;
    }

    return <ListItem>
        <Text as={"div"} fontWeight={"bold"} mt={"4"}>{t(name)}</Text>
        <VStack borderRadius={"md"} spacing={"0"} align={"stretch"} overflow={"hidden"} border={"solid 1px rgba(0, 0, 0, .2)"}>
            {entry[item]?.[0] && <Text p={"1"} bg={"red.50"} color={"red.900"}>{valueFormatter(entry[item][0] as V)}</Text>}
            {entry[item]?.[0] && entry[item]?.[1] && <Divider m={"0"} />}
            {entry[item]?.[1] && <Text p={"1"} bg={"green.50"} color={"green.900"}>{valueFormatter(entry[item][1] as V)}</Text>}
        </VStack>
    </ListItem>
}

export function XwgHistory({history}: {history: HistoryEntry[]}) {
    const {t} = useTranslation("cwg_index");

    if (!history.length) {
        return <>{t("There is no history entry for this xWG.")}</>
    }

    return <Stack spacing={"4"} divider={<StackDivider />}>
        {history?.map((entry, idx) => <Box key={idx}>
            <Heading size={"xs"}><FormattedDateTime date={entry.date} /> by <ProfileLink user={entry.user} /></Heading>
            <List>
                {entry.name && <HistoryItem name={"Name"} entry={entry} item={"name"} />}
                {entry.version && <HistoryItem name={"Version"} entry={entry} item={"version"} />}
                {entry.cat_no && <HistoryItem name={"Catalogue number"} entry={entry} item={"cat_no"} />}
                {entry.link && <HistoryItem name={"Link"} entry={entry} item={"link"} />}
                {entry.note && <HistoryItem name={"Note"} entry={entry} item={"note"} />}
                {entry.tags && <HistoryItem name={"Tags"} entry={entry} item={"tags"} valueFormatter={(tags) => tags.map(tag => tag.name).join(", ")} />}
                {entry.publish_status && <HistoryItem name={"Publish status"} entry={entry} item={"publish_status"} />}
                {entry.hold && <HistoryItem name={"Hold"} entry={entry} item={"hold"} valueFormatter={(value) => <ProfileLink user={value} />} />}
                {entry.images && <HistoryItem name={"Images"} entry={entry} item={"images"} valueFormatter={(value) => value.join(", ")} />}
            </List>
        </Box>)}
    </Stack>
}
