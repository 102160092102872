import {XwgExtendedCollection} from "../../models/xwg";
import React, {useCallback, useState} from "react";
import {CollectionData, updateCollection} from "../../api/updateCollection";
import {useAPI} from "../../api/api";
import {CollectionForm} from "./collectionForm";
import {useAlert} from "../generic/alert";
import {ValidationError} from "../../utils/errors";
import {Diagnostics} from "../../models/error";

type CollectionFormProps = {
    xwgId: number;
    onUpdateCollection?: (collection: XwgExtendedCollection[]) => void;
    existingCollection?: XwgExtendedCollection[];
    value: XwgExtendedCollection;
    onCancel?: () => void;
}
export const CollectionEditForm = (
    {
        xwgId,
        onUpdateCollection,
        existingCollection,
        value,
        onCancel
    }: CollectionFormProps
) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<Diagnostics>({detail: []});
    const {showConfirm} = useAlert();
    const api = useAPI({flash: "Saving..."});

    const collectionEdit = useCallback(async (match: Pick<CollectionData, "year" | "date">, data: CollectionData) => {
        setDisabled(true);

        if (existingCollection && (data.year !== match.year || data.date !== match.date) && existingCollection.some(collection => collection.year == data.year && collection.date == data.date)) {
            const result = await showConfirm(
                "Overwrite collection?",
                "Collection entry with matching year and date already exists. Continuing will overwrite that entry. Do you wish to continue?"
            );

            if (result !== "yes") {
                setDisabled(false);
                return;
            }

        }

        try {
            const newCollection = ((await updateCollection({
                api,
                match: value,
                data,
                xwgId: xwgId
            })) ?? []);

            if (onUpdateCollection) {
                onUpdateCollection(newCollection);
            }
        } catch (e) {
            if (e instanceof ValidationError) {
                setErrors(e.diagnostics);
            } else {
                console.error(e);
            }
        } finally {
            setDisabled(false);
        }
    }, [xwgId]);

    return <CollectionForm
        value={value}
        onCancel={onCancel}
        onSubmit={async (data) => {
            await collectionEdit({year: value.year, date: value.date}, data);
        }}
        errors={errors}
        disabled={disabled}
    />
}
