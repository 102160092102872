import {createFileRoute, redirect} from '@tanstack/react-router'
import {getAPI} from "../../../../api/api";
import {ListResponse} from "../../../../models/response";
import {User} from "../../../../models/user";
import {useTranslation} from "../../../../utils/helpers";
import React from "react";
import Columns from "../../../../components/generic/columns";
import {UserSearchForm} from "../../../../components/users/searchForm";
import {ListItem, UnorderedList} from "@chakra-ui/react";
import {ProfileLink} from "../../../../components/users/profileLink";
import {Route as UserProfileRoute} from "../_public_profile/index.$username";

export const Route = createFileRoute('/_site/users/search/$username')({
    component: () => {
        const {users} = Route.useLoaderData();
        const {t} = useTranslation("search");
        const navigate = Route.useNavigate();

        return <>
            <h2>{t("User search")}</h2>
            <Columns>
                <UserSearchForm
                    onSearch={async (search) => {
                        await navigate({
                            to: Route.fullPath,
                            params: {
                                username: search.username
                            }
                        });
                    }}
                />
                <div>
                    <UnorderedList>
                        {users.items.map(user => <ListItem key={user.id}>
                            <ProfileLink user={user} />
                        </ListItem>)}
                    </UnorderedList>
                </div>
            </Columns>
        </>;
    },
    params: {
        parse: (params: Record<string, string>) => ({
            username: params.username
        }),
        stringify: ({username}) => ({username})
    },
    loader: async ({context, params: {username}}) => {
        const api = getAPI(context);

        const resp = await api.post<ListResponse<User>>("/api/v2/users/query", {
            filter: {
                search: [username]
            },
            display: {
                attributes: ["id", "name"],
                order: ["name:ASC"]
            }
        });

        if (resp.data.count == 1) {
            throw redirect({
                to: UserProfileRoute.fullPath,
                params: {
                    username: resp.data.items[0].name
                }
            });
        }

        return {
            users: resp.data
        }
    }
});
