import {AnyRoute, redirect} from "@tanstack/react-router";
import {RouterContext} from "../../models/routerContext";
import {XwgFilter} from "../../models/xwgSearchQuery";
import {getAPI} from "../../api/api";
import {getTagCategories, Order} from "../../api/getTagCategories";
import {RedirectWithParams} from "./index";
import {TagCategory} from "../../models/tag";
import {firstLevelBeforeLoad, FirstLevelContext, FirstLevelParams, getSecondLevelParams} from "./first";
import {getThirdLevelParams, secondLevelBeforeLoad, SecondLevelContext, SecondLevelParams} from "./second";
import {ThirdLevelParams} from "./third";

export type ZeroLevelContext = {
    filter: XwgFilter;
    tagCategories: TagCategory[];
};

export type UpToZeroLevelContext = ZeroLevelContext & RouterContext;

export async function zeroLevelBeforeLoad(context: RouterContext, initialFilter: XwgFilter = {}): Promise<ZeroLevelContext> {
    const api = getAPI(context);

    const tagCategories = await getTagCategories({
        api,
        order: [Order.Count_DESC, Order.Name_ASC]
    });

    return {
        tagCategories,
        filter: initialFilter
    }
}

export async function zeroLevelRouteLoader({context}: {context: UpToZeroLevelContext}) {
    return {
        tagCategories: context.tagCategories,
    }
}

export async function zeroLevelIndexRedirect(to: AnyRoute, context: UpToZeroLevelContext | (UpToZeroLevelContext & FirstLevelContext)): Promise<RedirectWithParams<ThirdLevelParams>> {
    const firstLevelParams: FirstLevelParams = {
        primarySorting: (context as FirstLevelContext).primaryCategory ? `by-${(context as FirstLevelContext).primaryCategory.id}` : "by-country"
    };

    const firstLevelContext = await firstLevelBeforeLoad({
        context: context,
        params: firstLevelParams
    });

    const secondLevelParams: SecondLevelParams = getSecondLevelParams(firstLevelParams, {
        ...context,
        ...firstLevelContext
    });

    const secondLevelContext: SecondLevelContext = await secondLevelBeforeLoad({
        context: {
            ...context,
            ...firstLevelContext
        },
        params: secondLevelParams
    });

    const thirdLevelParams = getThirdLevelParams(secondLevelParams, {
        ...context,
        ...firstLevelContext,
        ...secondLevelContext
    });

    return {
        to: to.fullPath,
        params: thirdLevelParams,
        replace: true,
    }
}

export function zeroLevelIndexRoute(route: AnyRoute) {
    return {
        beforeLoad: async ({context}: {context: UpToZeroLevelContext}) => {
            throw redirect(await zeroLevelIndexRedirect(route, context));
        }
    }
}
