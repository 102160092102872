import {Xwg, XwgStatus, XwgWithId} from "../../models/xwg";
import React, {HTMLProps, useState} from "react";
import Popup from "./popup";
import {CwgImage} from "./image";
import CwgOverlay from "./overlay";
import {useTranslation} from "../../utils/helpers";
import TextTruncate from "react-text-truncate";
import {ROUTES} from "../../constants";
import {Link} from "@tanstack/react-router";
import {chakra, ListItem, ListItemProps} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";

const skeletonLoading = keyframes`
    0% {
        background-color: hsla(200, 20%, 80%, .5);
    }
    100% {
        background-color: hsla(200, 20%, 95%, .5);
    }
`;

const CwgNamePlaceholder = () => <chakra.span
    animation={`${skeletonLoading} 1s linear infinite alternate`}
    display={"block"}
    height={"2.4em"}
    borderRadius={".4em"}
/>

function CwgName({xwg}: { xwg?: Xwg } & HTMLProps<HTMLHeadingElement>) {
    const {t} = useTranslation("cwg_index");

    const props = {
        m: 0,
        fontWeight: 400,
        textOverflow: "ellipsis",
    };

    if (!xwg) {
        return <chakra.h4
            {...props}
        >
            <CwgNamePlaceholder />
        </chakra.h4>
    }

    return <chakra.h4
        {...props}
        title={xwg.name}
    >
        <TextTruncate
            element="span"
            line={xwg.version !== "1" ? 1 : 2}
            text={xwg.name}
        />
        {xwg.version !== "1" && <chakra.small
            color={(!xwg.isPublished || xwg.isArchived) ? "red" : "text"}
            display={"block"}
            textDecoration={(!xwg.isPublished || xwg.isArchived) ? "line-through" : "none"}
        >
            {t("version {{version}}", {version: xwg.version})}
        </chakra.small>}
    </chakra.h4>
}

export function CwgCatalogueItem({xwg, ...props}: { xwg?: XwgWithId } & ListItemProps) {
    const [status, setStatus] = useState<XwgStatus | undefined>(xwg?.status);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return <ListItem
        {...props}
        textAlign={"center"}
        position={"relative"}
    >
        {xwg ? <>
            <Popup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onStatusChange={(newStatus: XwgStatus) => setStatus(newStatus)}
                xwg={xwg}
                status={status}
            />
            <Link
                to={ROUTES.CWG_INDEX}
                params={{xwgId: xwg.id}}
                onClick={(e) => {
                    setIsPopupOpen(!isPopupOpen);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                style={{textDecoration: "none"}}
            >
                <CwgImage
                    images={xwg?.images}
                    overlay={xwg?.overlay}
                    m={2}
                />
                <CwgName xwg={xwg}/>
                {xwg && <CwgOverlay status={status} unofficial={!xwg.cat_no}/>}
            </Link>
        </> : <>
            <CwgImage m={2} />
            <CwgName />
        </>}
    </ListItem>
}
