import {createRouter as createReactRouter, parseSearchWith, stringifySearchWith} from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import {RISON} from "rison2";
import {RouterContext} from "./models/routerContext";
import pino from "pino";

export const emptyContext: RouterContext = {
    request: {
        url: "",
        cookies: {},
        grid: "",
        clientId: "",
        language: "",
        log: pino(),
    },
    currentUser: {
        currentUser: null,
        token: null,
        loaded: false
    },
    applicationData: {
        environment: "",
        version: "",
        announcements: []
    },
    scripts: [],
    links: []
}

export function createRouter() {
  return createReactRouter({
    routeTree,
    context: emptyContext,
    defaultPreload: false,
    parseSearch: parseSearchWith(RISON.parse),
    stringifySearch: stringifySearchWith(RISON.stringify),
  })
}

declare module '@tanstack/react-router' {
  interface Register {
    router: ReturnType<typeof createRouter>
  }
}
