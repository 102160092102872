import {HistoryEntry, XwgOffer, XwgUserCollection} from "../../models/xwg";
import {User} from "../../models/user";
import {Trans} from "react-i18next";
import {useTranslation} from "../../utils/helpers";
import React from "react";
import {FormattedDate} from "../generic/date";
import {NavigableTabs} from "../generic/tabs";
import {InfoBox} from "../generic/infoBox";
import {ProfileLink} from "../users/profileLink";
import {XwgHistory} from "./history";
import {Box, HStack, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";

type CwgTabsProps = {
    collected?: XwgUserCollection[],
    notWanted?: User[],
    offered?: XwgOffer[],
    wanted?: User[],
    history?: HistoryEntry[],
}

export function XwgOffers({offered}: {offered: Exclude<CwgTabsProps["offered"], undefined>}) {
    const {t} = useTranslation("cwg_index");

    return <Table>
        <Thead>
            <Tr>
                <Th>{t("User")}</Th>
                <Th>{t("Pieces")}</Th>
                <Th>{t("Note")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {offered.map((offer, idx) => <Tr key={idx}>
                <Td><ProfileLink user={offer.user} /></Td>
                <Td>{offer.pieces}</Td>
                <Td>{offer.comment}</Td>
            </Tr>)}
        </Tbody>
    </Table>
}

export function XwgCollected({collected}: {collected: Exclude<CwgTabsProps["collected"], undefined>}) {
    const {t} = useTranslation("cwg_index");

    return <Table>
        <Thead>
            <Tr>
                <Th>{t("User")}</Th>
                <Th>
                    <HStack>
                        <Box>{t("Date")}</Box>
                        <InfoBox><Trans ns={"cwg_index"}>In the Date column is date of <strong>last</strong> addition to user's collection.</Trans></InfoBox>
                    </HStack>
                </Th>
                <Th>{t("Pieces")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            <Tr>
                <Th colSpan={3}>
                    {t(
                        "Summary: {{people}} different people, {{pieces}}{{approx}} total pieces",
                        {
                            people: collected.length,
                            pieces: collected.reduce((acc, collected) => acc + (collected.pieces ?? 1), 0) ?? 0,
                            approx: collected.some(collected => collected.pieces === null) ? "±" : ""
                        }
                    )}
                </Th>
            </Tr>
            {collected.map((collected, idx) => <Tr key={idx}>
                <Td><ProfileLink user={collected.user} /></Td>
                <Td><FormattedDate date={collected.date} /></Td>
                <Td>{collected.pieces ?? <>1&plusmn;</>}</Td>
            </Tr>)}
        </Tbody>
    </Table>
}

export function XwgWants({wanted}: {wanted: Exclude<CwgTabsProps["wanted"], undefined>}) {
    const {t} = useTranslation("cwg_index");

    return <Table>
        <Thead>
            <Tr>
                <Th>{t("User")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {wanted.map((wanted, idx) => <Tr key={idx}>
                <Td><ProfileLink user={wanted} /></Td>
            </Tr>)}
        </Tbody>
    </Table>
}

export function XwgNotWants({notWanted}: {notWanted: Exclude<CwgTabsProps["notWanted"], undefined>}) {
    const {t} = useTranslation("cwg_index");

    return <Table>
        <Thead>
            <Tr>
                <Th>{t("User")}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {notWanted.map((item, idx) => <Tr key={idx}>
                <Td><ProfileLink user={item} /></Td>
            </Tr>)}
        </Tbody>
    </Table>
}

export function CwgTabs({offered, collected, wanted, notWanted, history}: CwgTabsProps) {
    const {t} = useTranslation("cwg_index");

    return <NavigableTabs tabs={[
        ...(offered ? [{label: t("Offers"), href: "offers", element: <>
            <h4>{t("Users, offering this xWG for exchange")}</h4>
            {offered.length
                ? <XwgOffers offered={offered} />
                : <p>{t("No one offers this xWG for exchange.")}</p>
            }
        </>}] : []),
        ...(collected ? [{label: t("Owns"), href: "owns", element: <>
            <h4>{t("Users, which has this xWG in their collection.")}</h4>
            {collected.length
                ? <XwgCollected collected={collected} />
                : <p>{t("No one has this xWG in collection.")}</p>
            }
        </>}] : []),
        ...(wanted ? [{label: t("Wants"), href: "wants", element: <>
            <h4>{t("Users who wants this xWG.")}</h4>
            {wanted.length
                ? <XwgWants wanted={wanted} />
                : <p>{t("No one wants this xWG.")}</p>
            }
        </>}] : []),
        ...(notWanted ? [{label: t("Doesn't want"), href: "not-want", element: <>
            <h4>{t("Users who doesn't want this xWG.")}</h4>
            {notWanted.length
                ? <XwgNotWants notWanted={notWanted} />
                : <p>{t("No one doesn't want this xWG.")}</p>
            }
        </>}] : []),
        {label: "History", href: "history", element: <>
            <h4>{t("History of changes")}</h4>
            {history && <XwgHistory history={history} />}
        </>}
    ]} />
}
