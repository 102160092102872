import {createFileRoute} from '@tanstack/react-router'
import React from "react";
import {SendMessageForm} from "../../../components/messages/sendMessage";
import {Route as ThreadRoute} from "./$threadId";

export const Route = createFileRoute('/_site/messages/')({
    component: () => {
        const navigate = Route.useNavigate();
        return <SendMessageForm
                hideBelow={"lg"}
                onMessageSent={async (data) => {
                await navigate({
                    to: ThreadRoute.fullPath,
                    params: {
                        threadId: data.threadId
                    }
                });
            }} />;
    }
});
