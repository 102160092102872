import {createFileRoute, Link, Outlet, useMatches} from '@tanstack/react-router'

import React from "react";
import {useTranslation} from "../../../../utils/helpers";
import {Route as RouteAdvancedWithFilter} from "./advanced/$filter";
import {Route as RoutePrimarySorting} from "./$primarySorting/index";
import {ContentHeader} from "../../../../components/contentHeader";
import {Button} from "@chakra-ui/react";

import {zeroLevelBeforeLoad} from "../../../../utils/collectionListing";
import {findInMatchesData} from "../../../../utils/findInMatchesData";
import {TagCategory} from "../../../../models/tag";
import {XwgFilter} from "../../../../models/xwgSearchQuery";
import {ZeroLevelContext, zeroLevelRouteLoader} from "../../../../utils/collectionListing/zero";

export const Route = createFileRoute('/_site/cwg/browse')({
    component: CwgBrowseHeader,
    beforeLoad: async ({context}): Promise<ZeroLevelContext & {isAdvanced: boolean}> => {
        return {
            ...(await zeroLevelBeforeLoad(context)),
            isAdvanced: false
        }
    },
    loader: zeroLevelRouteLoader,
});

function CwgBrowseHeader() {
    const {t} = useTranslation("browse");
    const ldr = Route.useLoaderData();

    if (!ldr) {
        return <Outlet />
    }

    const {tagCategories} = ldr;
    const matches = useMatches();
    const primaryCategory = findInMatchesData<TagCategory>(matches, "primaryCategory");
    const isAdvanced = findInMatchesData<boolean>(matches, "isAdvanced");
    const filter = findInMatchesData<XwgFilter>(matches, "filter");

    return <>
        <ContentHeader
            title={t("Browse catalogue")}
            toolbarTitle={t("Primary sorting:")}
            toolbarButtons={[
                ...tagCategories.map(category =>
                    <Button
                        size={"sm"}
                        key={category.id}
                        as={Link}
                        to={RoutePrimarySorting.fullPath}
                        params={{
                            primarySorting: `by-${category.id}`
                        }}
                        isActive={primaryCategory?.id === category.id}
                    >
                        {category.name}
                    </Button>
                ),
                <Button
                    key={"advanced"}
                    size={"sm"}
                    as={Link}
                    to={RouteAdvancedWithFilter.fullPath}
                    params={{
                        filter: filter ?? {}
                    }}
                    isActive={isAdvanced}
                >
                    {t("Advanced")}
                </Button>
            ]}
        />
        <Outlet />
    </>
}
