import React, {forwardRef, useRef} from "react";
import {useTranslation} from "../../utils/helpers";
import {useFormContext} from "react-hook-form";
import {ErrorMessage} from "../generic/form";
import emptyCwgImage from "../../assets/static/unknown.jpg";
import mergeRefs from "merge-refs";
import {Button} from "../generic/buttons";
import {chakra, Flex, FlexProps, useTheme} from "@chakra-ui/react";

type CommonProps = {
    overlay?: boolean;
    backside?: string;
}

type CwgImageUploadProps = CommonProps & FlexProps

type SingleCwgImageUploadProps = {
    name: string;
    required?: boolean;
} & CommonProps & FlexProps;

const SingleCwgImageUpload = forwardRef<HTMLDivElement, SingleCwgImageUploadProps>((
    {
        overlay,
        backside,
        name,
        required,
        ...props
    }, ref) =>
{
    const {t} = useTranslation("cwg_ops");
    const inputFileRef = useRef<HTMLInputElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const {register, formState: { errors } } = useFormContext();

    const {ref: formRef, onChange, ...rest} = register(name, {
        validate: () => {
            if (!required) {
                return true;
            }
            return (inputFileRef.current?.files?.length ?? 0) > 0 || t("Front side image is required.");
        }
    })

    const theme = useTheme();

    return <Flex
        ref={ref}
        flexDirection={"column"}
        borderRadius={"md"}
        bgColor={errors[name] ? "red.100" : "transparent"}
        p={4}
        {...props}
    >
        <chakra.img
            src={backside ?? emptyCwgImage}
            alt="xWG"
            onClick={() => {
                inputFileRef.current?.click();
            }}
            ref={imgRef}
            clipPath={(overlay ?? true) ? theme.overlay : undefined}
            maxW={"100%"}
            cursor={"pointer"}
            mb={4}
        />
        <chakra.input
            type={"file"}
            {...rest}
            ref={mergeRefs(inputFileRef, formRef)}
            accept={"image/*"}
            display={"none"}
            onChange={(e) => {
                const target = e.currentTarget as HTMLInputElement;
                if ((target.files?.length ?? 0) > 0) {
                    const file = (target.files ?? [])[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        if (imgRef.current) {
                            imgRef.current?.setAttribute("src", e.target?.result as string);
                        }
                    };
                    reader.readAsDataURL(file);
                }

                if (onChange) {
                    onChange(e);
                }
            }}
        />
        <Button
            variant={"secondary"}
            type="button"
            onClick={() => {
                inputFileRef.current?.click();
            }}
        >
            {t("Choose")}
        </Button>
        <ErrorMessage errors={errors} name={name} />
    </Flex>
});

function CwgImageUpload(
    {
        overlay,
        backside,
        ...props
    }: CwgImageUploadProps
) {
    const {t} = useTranslation("cwg_ops");

    return <Flex
        {...props}
        gap={8}
    >
        <Flex
            flexGrow={"1"}
            flexBasis={"50%"}
            flexDirection={"column"}
            width={"50%"}
        >
            <chakra.label
                alignSelf={"center"}
                fontWeight={"bold"}
                mb={0}
            >
                {t("Front side")}
            </chakra.label>
            <SingleCwgImageUpload overlay={overlay} name={"frontside"} required={true} />
        </Flex>
        <Flex
            flexGrow={"1"}
            flexBasis={"50%"}
            flexDirection={"column"}
            width={"50%"}
        >
            <chakra.label
                alignSelf={"center"}
                fontWeight={"bold"}
                mb={0}
            >
                {t("Back side")}
            </chakra.label>
            <SingleCwgImageUpload overlay={overlay} backside={backside} name={"backside"} />
        </Flex>
    </Flex>;
}

export default CwgImageUpload;

export {
    SingleCwgImageUpload
};

export type {
    CwgImageUploadProps,
    SingleCwgImageUploadProps
};
