import {PublishStatus, XwgWithId} from "../../models/xwg";
import {useCurrentUser, User} from "../../models/user";
import React, {useCallback, useState} from "react";
import {useTranslation} from "../../utils/helpers";
import {useAPI} from "../../api/api";
import {CwgImage} from "./image";
import {Dd, Dl, Dt} from "../generic/dl";
import {CwgCategories} from "./categories";
import {FormattedDate} from "../generic/date";
import {TokenScope} from "../../models/tokenScope";
import {
    BoxProps,
    ButtonGroup,
    Flex,
    Stack,
    Tag,
    TagCloseButton,
    TagLabel,
    Text,
    Tooltip,
    useMediaQuery,
    useTheme,
    useToast
} from "@chakra-ui/react";

import holdIcon from "../../assets/static/icons/hold.svg";
import archiveIcon from "../../assets/static/icons/archive.svg";
import publishIcon from "../../assets/static/icons/publish.svg";
import retractIcon from "../../assets/static/icons/retract.svg";
import {ProfileLink} from "../users/profileLink";
import {UserTag} from "../../models/tag";
import {Button} from "../generic/buttons";
import {AddTagForm} from "./addTagForm";


type CwgInfoProps = {
    xwg: XwgWithId,
    publishStatus?: PublishStatus,
    setPublishStatus?: (status: PublishStatus) => void,
    onHoldBy?: User | null,
    setOnHoldBy?: (user: User | null) => void,
};

export function CwgInfo({
    xwg,
    publishStatus,
    setPublishStatus,
    onHoldBy,
    setOnHoldBy,
    ...props
}: CwgInfoProps & BoxProps) {
    const {t} = useTranslation(["cwg_index", "review", "tags"]);
    const currentUser = useCurrentUser();
    const api = useAPI();
    const [savingPublish, setSavingPublish] = useState<boolean>(false);
    const toast = useToast();

    const callPublishStatus = useCallback(async (publishStatus: PublishStatus) => {
        setSavingPublish(true);
        try {
            await api.patch("/api/v2/xwg/" + xwg.id, {
                "publish_status": publishStatus
            });

            if (setPublishStatus) {
                setPublishStatus(publishStatus);
            }

            if (setOnHoldBy) {
                if (publishStatus == "hold") {
                    setOnHoldBy(currentUser);
                } else {
                    setOnHoldBy(null);
                }
            }
        } finally {
            setSavingPublish(false);
        }
    }, [xwg, currentUser]);

    const [userTags, setUserTags] = useState<UserTag[]>((xwg.tags?.filter(tag => (tag as UserTag).user) ?? []) as UserTag[]);

    const theme = useTheme();
    const [isLargerThanSm] = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

    return <Flex
        {...props}
        gap={4}
        alignItems={"flex-start"}
        flexDirection={isLargerThanSm ? "row" : "column"}
    >
        <CwgImage
            images={xwg.images}
            overlay={xwg.overlay}
            maxW={"12em"}
            alignSelf={isLargerThanSm ? "flex-start" : "center"}
            showAllStacked={true}
        />
        <Dl>
            <Dt>{t("Category:")}</Dt>
            <Dd><CwgCategories xwg={xwg} /></Dd>

            <Dt>{t("Version:")}</Dt>
            <Dd>{xwg.version}</Dd>

            <Dt>{t("Catalogue number:")}</Dt>
            <Dd>{xwg.cat_no ?? t("Uncatalogized xWG")}</Dd>

            {xwg.created_by && <>
                <Dt>{t("Added by:")}</Dt>
                <Dd><ProfileLink user={xwg.created_by} /></Dd>
            </>}

            {xwg.created && <>
                <Dt>{t("Added:")}</Dt>
                <Dd><FormattedDate date={xwg.created} /></Dd>
            </>}

            {xwg.link && <>
                <Dt>{t("Profile:")}</Dt>
                <Dd><a href={xwg.link} target="_blank">{t("here")}</a></Dd>
            </>}

            {xwg.note && <>
                <Dt>{t("Note:")}</Dt>
                <Dd>{xwg.note}</Dd>
            </>}

            {currentUser?.has(TokenScope.ADMIN_PUBLISH) && publishStatus && <>
                <Dt>{t("Review status:", {ns: "review"})}</Dt>
                <Dd>
                    <Text color={{
                        "new": "blue.700",
                        "published": "green.700",
                        "archived": "red.700",
                        "hold": "yellow.700",
                    }[publishStatus]}>
                        {t(
                            {
                                "new": "New",
                                "published": "Published",
                                "archived": "Archived",
                                "hold": "On hold by {{user}}"
                            }[publishStatus],
                            {ns: "review", user: onHoldBy?.name ?? ""}
                        )}
                    </Text>
                </Dd>
                <Dd>
                    <ButtonGroup isAttached size={"sm"} variant={"secondary"}>
                        {(publishStatus === "new" && onHoldBy?.id !== currentUser?.id) &&
                            <Button
                                disabled={savingPublish}
                                onClick={() => callPublishStatus("hold")}
                                icon={holdIcon}
                            >
                                {t("Hold", {"ns": "review"})}
                            </Button>
                        }
                        {(publishStatus === "hold" && onHoldBy?.id === currentUser?.id) &&
                            <Button
                                disabled={savingPublish}
                                onClick={() => callPublishStatus("new")}
                                icon={holdIcon}
                            >
                                {t("Unhold", {"ns": "review"})}
                            </Button>
                        }
                        {(publishStatus === "new" || publishStatus === "hold") &&
                            <Button
                                disabled={savingPublish}
                                onClick={() => callPublishStatus("published")}
                                icon={publishIcon}
                            >
                                {t("Publish", {"ns": "review"})}
                            </Button>
                        }
                        {publishStatus === "archived" &&
                            <Button
                                disabled={savingPublish}
                                onClick={() => callPublishStatus("published")}
                                icon={archiveIcon}
                            >
                                {t("Unarchive", {"ns": "review"})}
                            </Button>
                        }
                        {(publishStatus === "published" || publishStatus === "archived") &&
                            <Button
                                disabled={savingPublish}
                                onClick={() => callPublishStatus("new")}
                                icon={retractIcon}
                            >
                                {t("Retract", {"ns": "review"})}
                            </Button>
                        }
                        {publishStatus !== "archived" &&
                            <Button
                                disabled={savingPublish}
                                onClick={() => callPublishStatus("archived")}
                                icon={archiveIcon}
                            >
                                {t("Archive", {"ns": "review"})}
                            </Button>
                        }
                    </ButtonGroup>
                </Dd>
            </>}

            {currentUser && <>
                <Dt>{t("Tags:")}</Dt>
                <Dd>
                    <AddTagForm onCreate={async (newTag) => {
                        setUserTags(
                            (await api.post<UserTag[]>(`/api/v2/xwg/${xwg.id}/tags/`, [newTag])).data
                        );
                        toast({
                            title: t("Tag {{tag}} has been added to the xWG.", {tag: newTag}),
                            status: "success",
                        });
                    }} />
                </Dd>
                <Dd style={{gridColumn: "1/3"}}>
                    <Stack spacing={2} wrap={"wrap"} direction={"row"}>
                        {userTags.map((tag) => <Tag variant={"tag"} key={tag.id}>
                            <TagLabel>{tag.name}</TagLabel>
                            <Tooltip label={t("Click to remove this tag from this xWG.")}>
                                <TagCloseButton onClick={async () => {
                                    await api.delete(`/api/v2/xwg/${xwg.id}/tags/${encodeURIComponent(tag.id)}`);
                                    setUserTags((await api.get<UserTag[]>(`/api/v2/xwg/${xwg.id}/tags/`)).data);
                                    toast({
                                        title: t("Tag {{tag}} has been removed from the xWG.", {tag: tag.name}),
                                        status: "success",
                                    });
                                }} />
                            </Tooltip>
                        </Tag>)}
                    </Stack>
                </Dd>
            </>}
        </Dl>
    </Flex>
}
