import {useMemo} from "react";
import {Model} from "./model";
import {RouterContext} from "./routerContext";
import {useRouter} from "@tanstack/react-router";
import {SplittedScopes} from "./tokenScope";
import {ListResponse} from "./response";

export type Statistics = {
    pieces: number;
    approximate: boolean;
    unique: number;
}

export type TagStatistics = {
    category: {
        id: string;
        name: string;
        category: {
            id: string;
            name: string;
        }
    }
} & Statistics;

export type UserEmail = {
    email: string;
    verified: boolean;
    primary: boolean;
    receive_privmsg: boolean;
    receive_join: boolean;
    language: string;
}

export interface User {
    id: number;
    name: string;
    email: string;
    private: boolean;
    hide_from_top: boolean;
    profile: string;
    registration_date: string;
    last_login: string;
    total: Statistics;
    offers: Statistics;
    tag_statistics: TagStatistics[];
    emails: UserEmail[];
    groups: ExistingGroup[];
    scopes: SplittedScopes;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface UserModel extends User {}

export interface CurrentUser extends User {
    scope: string[]
}

export class CurrentUser extends Model<CurrentUser> {
    /**
     * Check if the user has a specific scope
     * @param scope The scope to check
     */
    has(scope: string): boolean {
        return this.scope.includes(scope);
    }
}

export class UserModel extends Model<User> {
}

export function useCurrentUser(): CurrentUser | null {
    const {state: {matches}} = useRouter();
    const currentUser = (matches.find(match => (match.loaderData as RouterContext)?.currentUser)?.loaderData as RouterContext)?.currentUser ?? {currentUser: null};

    return useMemo(
        () => currentUser.currentUser && new CurrentUser(currentUser.currentUser),
        [currentUser.currentUser]
    );
}

export type UserFilter = {
    ids?: number[];
    names?: string[];
    search?: string[];
    hide_from_top?: boolean;
    min_collected?: number;
    min_offered?: number;
    offers_exclude_owned?: boolean;
    groups?: number[];
    scopes?: string[];
    scope_match_mode?: "all" | "any";
}

export type UserAttribute = "id" | "name" | "private" | "hide_from_top" | "profile" | "registration_date" | "last_login" | "total" | "total.pieces" | "total.unique" | "tag_statistics" | "scopes" | "offers" | "offers.pieces" | "offers.unique" | "email" | "emails";

export type UserDisplayOptions = {
    order?: ("id:ASC" | "id:DESC" | "name:ASC" | "name:DESC" | "count:ASC" | "count:DESC" | "offers:ASC" | "offers:DESC")[];
    limit?: number;
    offset?: number;
    attributes?: UserAttribute[];
}

export type UserRequest = {
    filter?: UserFilter;
    display?: UserDisplayOptions;
}

export type UserResponse = ListResponse<UserModel>;

export type UserSearch = {
    username: string;
}

export type Group = {
    name: string;
};

export type ExistingGroup = Group & {id: number};
export type GroupUsers = {users: User[]};
export type GroupScopes = {scopes: SplittedScopes};
