import {SecondLevelParams, UpToSecondLevelContext} from "./second";
import {ExtendedTagInCategory} from "../../models/tag";
import {getAPI} from "../../api/api";
import {TagInCategoryFilter, XwgDisplayOptions, XwgFilter, XwgOrder} from "../../models/xwgSearchQuery";
import {listXwg} from "../../api/listXwg";
import {CwgCatalogue} from "../../components/cwg/cwgCatalogueView";
import {CwgCatalogueSecondaryTabs} from "../../components/cwg/cwgCatalogueSecondaryTabs";
import {NotFoundError} from "../errors";
import {RouteComponent} from "@tanstack/react-router";
import _ from "lodash";
import {ErrorComponent} from "../../components/error";
import {RouterContext} from "../../models/routerContext";

export type ThirdLevelParams = {
    secondaryCategoryName: string;
} & SecondLevelParams;

export type ThirdLevelContext = {
    secondaryCategoryTag: ExtendedTagInCategory;
};

export type UpToThirdLevelContext = ThirdLevelContext & UpToSecondLevelContext;

export type QueryParams = {
    offset: number,
    limit: number,
    order: string,
    direction: string
};

export type ThirdLevelRouteOptions<SearchParams extends object = object> = {
    component?: RouteComponent<any>,
    validateSearch?: (search: Record<string, unknown>) => SearchParams,
    additionalDisplayOptions?: (opts: {search: QueryParams & SearchParams}) => Partial<XwgDisplayOptions>,
    beforeLoad?: (opts: {context: UpToThirdLevelContext, params: ThirdLevelParams, search: QueryParams & SearchParams}) => Promise<ThirdLevelContext>,
}

export function loadXwgs<SearchParams extends object = object>(opts: ThirdLevelRouteOptions<SearchParams>) {
    return async ({context, deps: search}: {context: RouterContext & {filter: XwgFilter}, deps: QueryParams}) => {
        const api = getAPI(context);

        const display: XwgDisplayOptions = _.merge({
            order: [
                ...(search.order ? [`${search.order}:${search.direction}` as XwgOrder] : []),
                `name:${search.direction}` as XwgOrder,
                `version:${search.direction}` as XwgOrder
            ],
            limit: search.limit,
            offset: search.offset,
            attributes: ["id", "name", "version", "images", "cat_no", "status", "tags"]
        }, opts.additionalDisplayOptions?.({
            search: search as QueryParams & SearchParams
        }) ?? {});

        return {
            filter: context.filter,
            xwgs: await listXwg({
                api,
                query: {
                    filter: context.filter,
                    display: display
                }
            })
        }
    }
}

export function validateSearch<SearchParams extends object = object>(additionalValidator: ((search: Record<string, unknown>) => SearchParams) | undefined = undefined){
    return (search: Record<string, unknown>): (SearchParams & QueryParams) => {
        const s = search ?? {};

        const possibleSortColumns = ["name", "cat_no", "created", "updated", "published"];
        const possibleSortDirections = ["ASC", "DESC"];

        if (!possibleSortColumns.includes(s.order as string)) {
            s.order = "name";
        }

        if (!possibleSortDirections.includes(s.direction as string)) {
            s.direction = "ASC";
        }

        return {
            offset: parseInt(s.offset as string ?? "0"),
            limit: parseInt(s.limit as string ?? "36"),
            order: s.order as string,
            direction: s.direction as string,
            ...(additionalValidator?.(s) ?? {})
        } as (SearchParams & QueryParams);
    };
}

export function loaderDeps<SearchParams extends object = object>({search}: {search: QueryParams & SearchParams}) {
    return search;
}

export function thirdLevelIndexRoute<SearchParams extends object = object>(opts: ThirdLevelRouteOptions<SearchParams> = {}) {
    return {
        component: opts.component ?? CwgCatalogue,
        validateSearch: validateSearch<SearchParams>(opts.validateSearch),
        loaderDeps: loaderDeps<SearchParams>,
        loader: loadXwgs<SearchParams>(opts),
        beforeLoad: opts.beforeLoad,
    }
}

export function thirdLevelLayoutRoute() {
    return {
        errorComponent: ErrorComponent,
        component: CwgCatalogueSecondaryTabs,
        beforeLoad: async ({context, params}: {context: UpToSecondLevelContext, params: ThirdLevelParams}): Promise<ThirdLevelContext> => {
            if (!context.filter.tags) {
                context.filter.tags = [];
            }

            const secondaryCategoryTag = context.secondaryCategory.tags.find(tag => tag.id === params.secondaryCategoryName);

            if (!secondaryCategoryTag) {
                throw new NotFoundError();
            }

            const existingFilter = context.filter.tags.find(tag => typeof(tag) === "object" && tag.category === context.secondaryCategory.id);
            if (existingFilter) {
                (existingFilter as TagInCategoryFilter).name = secondaryCategoryTag.id;
            } else {
                context.filter.tags.push({
                    category: context.secondaryCategory.id,
                    name: secondaryCategoryTag.id
                });
            }

            return {
                secondaryCategoryTag
            }
        },
        loader: ({context}: {context: UpToThirdLevelContext}) => ({
            secondaryCategoryTag: context.secondaryCategoryTag
        })
    }
}
