import {CollapsibleTabList} from "../generic/tabs";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import {Outlet, useMatch, useMatches} from "@tanstack/react-router";
import {TabPanels, Tabs} from "@chakra-ui/react";
import {findInMatchesData} from "../../utils/findInMatchesData";
import {ExtendedTagInCategory, TagCategory} from "../../models/tag";

export function CwgCatalogueSecondaryTabs() {
    const {t} = useTranslation("browse");

    const match = useMatch({strict: false});
    const matches = useMatches();

    const secondaryCategory = findInMatchesData<TagCategory>(matches, "secondaryCategory");
    const secondaryCategoryTag = findInMatchesData<ExtendedTagInCategory>(matches, "secondaryCategoryTag");

    return <>
        <Tabs variant={"secondary"} index={secondaryCategory?.tags.findIndex(tag => tag.id == secondaryCategoryTag?.id)}>
            <CollapsibleTabList
                tabs={secondaryCategory?.tags.map(tag => ({
                    to: match.fullPath,
                    params: Object.assign({}, match, {secondaryCategoryName: tag.id}),
                    label: tag.name,
                    tooltip: t("{{count}} items", {count: tag.count})
                })) ?? []}
            />
            <TabPanels>
                <Outlet />
            </TabPanels>
        </Tabs>
    </>
}
