import {createFileRoute, Link} from '@tanstack/react-router'
import React from "react";
import {Button, Divider, HStack} from "@chakra-ui/react";
import {Route as ApplicationsRoute} from "../applications/index";
import {combineTitle} from "../../../utils/combineTitle";
import {getFixedT} from "../../../utils/getFixedT";
import {useTranslation} from "../../../utils/helpers";
import {Trans} from "react-i18next";

export const Route = createFileRoute('/_site/cwg-main/api')({
  meta: ({matches}) => {
    const t = getFixedT("api");
    return combineTitle(matches, t("API"))
  },
  component: () => {
    const {t} = useTranslation("api");

    return <>
      <h2>{t("API")}</h2>
      <h3>{t("API v2")}</h3>

      <p>{t("Current version of REST API for accessing the collection. It is currently the only one supported.")}</p>

      <p>{t("The API is regularly tested before each release, so it is considered stable. Also, this website uses it entirely, so everything that the website can do is possible through the API.")}</p>

      <HStack mt={6}>
        <Button as={"a"} href={"/api/v2/docs"} rel={"nofollow"}>{t("API Documentation")}</Button>
        <Button as={Link} to={ApplicationsRoute.fullPath}>{t("Applications management")}</Button>
      </HStack>

      <Divider/>

      <h3>{t("API v1 and Legacy API")}</h3>
      <p>
        <Trans
            ns={"api"}
            defaults={"This API still can be used until the old website is terminated. Proceed to <0>old website</0> to learn more."}
            components={[
              <a href={"https://cwg.gcm.cz/cwg-main/api/"}/>
            ]}
        />
      </p>
      <p>{t("Note that you should not use this API, as the termination of old website is imminent, because of technical reasons, which do not allow to run it any more.")}</p>
    </>
  }
});
