import {createFileRoute} from '@tanstack/react-router'
import {getAPI} from "../../../../api/api";
import {getTagCategories} from "../../../../api/getTagCategories";

export const Route = createFileRoute('/_site/cwg/search')({
    loader: async ({context}) => {
        const api = getAPI(context);

        const tagCategories = await getTagCategories({api});

        return {
            tagCategories,
        }
    }
});
