import {XwgWithId} from "../../models/xwg";
import {Table, TableCellProps, Tbody, Td, Th, Thead, Tooltip, Tr} from "@chakra-ui/react";
import {useTranslation} from "../../utils/helpers";
import unofficialImg from "../../assets/static/status/unofficial.png";
import React from "react";
import {Link} from "@tanstack/react-router";
import {Route as XwgIndexRoute} from "../../routes/_site/cwg/index.$xwgId/index";
import {FormattedDate} from "../generic/date";

function BaseCells({xwg, ...props}: {xwg: XwgWithId} & TableCellProps) {
    const {t} = useTranslation("browse");
    return <>
        <Td {...props} whiteSpace={"nowrap"}>{xwg.cat_no}</Td>
        <Td {...props} width={"1em"} pl={0} pr={0}>
            {!xwg.cat_no ? <Tooltip label={t("xWG is not part of official catalogue")}><img src={unofficialImg} /></Tooltip> : null}
        </Td>
        <Td {...props}>
            <Link to={XwgIndexRoute.fullPath} params={{xwgId: xwg.id}}>{xwg.name}</Link>
        </Td>
        <Td {...props}>
            {xwg.version}
        </Td>
    </>;
}

function ExtendedRow({xwg}: {xwg: XwgWithId}) {
    const {t} = useTranslation("browse");
    return <>
        <Tr>
            <BaseCells borderBottom={"none"} xwg={xwg} />
            <Td colSpan={4}>{t("Multiple collection entries:")}</Td>
        </Tr>
        {xwg.collection?.map((collectionItem, idx) => <Tr key={idx}>
            <Td colSpan={4} {...(idx < (xwg.collection?.length ?? 0) - 1) ? {borderBottom: "none"} : {}}></Td>
            <Td textAlign={"right"}>{collectionItem.pieces}</Td>
            <Td><FormattedDate date={collectionItem.date} /></Td>
            <Td>{collectionItem.year}</Td>
            <Td>{collectionItem.comment}</Td>
        </Tr>)}
    </>;
}

function SimpleRow({xwg}: {xwg: XwgWithId}) {
    const collectionItem = xwg.collection?.[0];

    return <Tr>
        <BaseCells xwg={xwg} />
        <Td textAlign={"right"}>{collectionItem?.pieces ?? <>1&pm;</>}</Td>
        <Td><FormattedDate date={collectionItem?.date ?? xwg.status?.last_collected_date} /></Td>
        <Td>{collectionItem?.year}</Td>
        <Td>{collectionItem?.comment}</Td>
    </Tr>
}

export function OverviewTableView({items}: {items: XwgWithId[]}) {
    const {t} = useTranslation("browse");

    return <Table mb={4}>
        <Thead>
            <Tr>
                <Th>{t("Cat. no.")}</Th>
                <Th p={0}></Th>
                <Th>{t("Name")}</Th>
                <Th>{t("Version", {ns: "cwg_index"})}</Th>
                <Th>{t("Pieces", {ns: "cwg_index"})}</Th>
                <Th>{t("Date", {ns: "cwg_index"})}</Th>
                <Th>{t("Year", {ns: "cwg_index"})}</Th>
                <Th>{t("Note", {ns: "cwg_index"})}</Th>
            </Tr>
        </Thead>
        <Tbody>
            {items.map(xwg => (xwg.collection?.length ?? 0) > 1
                ? <ExtendedRow key={xwg.id} xwg={xwg}/>
                : <SimpleRow key={xwg.id} xwg={xwg}/>
            )}
        </Tbody>
    </Table>
}
