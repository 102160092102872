import {XwgWithId} from "../../models/xwg";
import {useTranslation} from "../../utils/helpers";
import {Box, Flex, ListItem, Table, TableProps, Tbody, Td, Text, Th, Thead, Tr, UnorderedList} from "@chakra-ui/react";
import React from "react";
import {CwgImage} from "./image";
import CwgOverlay from "./overlay";
import {Link} from "@tanstack/react-router";
import {Route as XwgIndexRoute} from "../../routes/_site/cwg/index.$xwgId/index";
import TextTruncate from "react-text-truncate";

function TableView({items, ...props}: {items: XwgWithId[]} & TableProps) {
    const {t} = useTranslation("browse");
    const maxRows = 2;

    return (
        <Table {...props}>
            <Thead>
                <Tr>
                    <Th colSpan={2}>
                        {t("Name")}
                    </Th>
                    <Th>
                        {t("Pieces")}
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {items.map((xwg) => {
                    const comments = xwg.collection?.filter(collection => collection.comment) ?? [];
                    return (
                        <Tr key={xwg.id}>
                            <Td className={"td-shrink"}>
                                <Box position={"relative"}>
                                    <CwgImage
                                        images={xwg?.images}
                                        overlay={xwg?.overlay}
                                        style={{
                                            width: "6em",
                                        }}
                                    />
                                    {xwg && <CwgOverlay status={xwg.status} unofficial={!xwg.cat_no}/>}
                                </Box>
                            </Td>
                            <Td verticalAlign={"top"}>
                                <Link
                                    to={XwgIndexRoute.fullPath}
                                    params={{
                                        xwgId: xwg.id
                                    }}
                                    style={{
                                        fontWeight: 600
                                    }}
                                >
                                    {xwg.name}
                                </Link>
                                {xwg.version !== "1" && " " + t("(version {{version}})", {version: xwg.version})}
                                {xwg.cat_no && <Text as={"div"} fontSize={"2xs"}>{xwg.cat_no}</Text>}

                                {(comments.length > 0) && <UnorderedList mt={1}>
                                    {comments.map((collection, idx) => (idx <= (xwg.cat_no ? maxRows - 2 : maxRows - 1)) && <ListItem key={idx}>
                                        <TextTruncate line={1} text={collection.comment} />
                                    </ListItem>)}
                                    {comments.length > (xwg.cat_no ? 2 : 1) && <ListItem>
                                        <Link to={XwgIndexRoute.fullPath} params={{xwgId: xwg.id}}>
                                            {t("... and {{count}} more rows", {count: comments.length - (xwg.cat_no ? maxRows : maxRows - 1)})}
                                        </Link>
                                    </ListItem>}
                                </UnorderedList>}
                            </Td>
                            <Td fontSize={"lg"}>
                                {xwg.collection?.reduce((acc, collection) => acc + collection.pieces, 0) ?? 1}
                            </Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    )
}

export function TableWithImagesView({items}: {items: XwgWithId[]}) {
    const leftSide = items.slice(0, Math.ceil(items.length / 2));
    const rightSide = items.slice(Math.ceil(items.length / 2));

    return <Flex flexWrap={"wrap"} flexDirection={"row"} mb={4} gap={"1em"}>
        <TableView items={leftSide} flexBasis={"calc(50% - 1em)"} flexGrow={1} flexShrink={1} />
        <TableView items={rightSide} flexBasis={"calc(50% - 1em)"} flexGrow={1} flexShrink={1} />
    </Flex>;
}
