import {defineStyle, defineStyleConfig} from "@chakra-ui/styled-system";
import {rgba} from "polished";

const shadedVariant = defineStyle((props) => {
    const { colorScheme: c } = props;

    return {
        fontWeight: "bold",
        bgColor: `${c}.400`,
        bgGradient: `linear(to-t, color-mix(in hsl, ${c}.400, black 10%), color-mix(in hsl, ${c}.400, white 20%))`,
        color: `white`,
        border: "solid 1px",
        borderColor: `${c}.400`,
        borderRadius: ".2em",
        cursor: "pointer",
        _hover: {
            bgColor: `${c}.400`,
            bgGradient: `linear(to-t, color-mix(in hsl, ${c}.400, white 20%), color-mix(in hsl, ${c}.400, black 10%))`,
            color: `${c}.900`,

            _disabled: {
                bgColor: `${c}.400`,
                bgGradient: `linear(to-t, color-mix(in hsl, ${c}.400, black 10%), color-mix(in hsl, ${c}.400, white 20%))`,
            }
        },
        _active: {
            bgColor: `${c}.400`,
            bgGradient: `linear(to-t, color-mix(in hsl, ${c}.400, white 20%), color-mix(in hsl, ${c}.400, black 10%))`,
            color: `${c}.900`,
        },
        "&:link": {
            _active: {
                color: `${c}.900`,
            },
            color: `white`,
            textDecoration: "none",
        },
        _visited: {
            color: "white",
            _active: {
                color: `${c}.900`,
            }
        }
    };
});

const secondaryVariant = defineStyle({
    fontWeight: "normal",
    bg: "#EBCD9D linear-gradient(to bottom, #EBCD9D, #D9BD91)",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#B39B77",
    color: "black",
    cursor: "pointer",

    ".chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)":
      { marginEnd: "-1px" },
    ".chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)":
      { marginBottom: "-1px" },

    _hover: {
        color: "black",
        background: "#EBCD9D linear-gradient(to top, #EBCD9D, #D9BD91)"
    },

    _active: {
        color: "black",
        background: "#EBCD9D linear-gradient(to top, #EBCD9D, #D9BD91)",
    },

    "&.active": {
        color: "black",
        background: "#EBCD9D linear-gradient(to top, #EBCD9D, #D9BD91)",
    },

    _focus: {
        color: "black",
        background: "#EBCD9D linear-gradient(to top, #EBCD9D, #D9BD91)",
    },
});

const ghostVariant = defineStyle({
    cursor: "pointer",
    color: "black",
    textDecoration: "none",

    "&:link": {
        textDecoration: "none"
    },

    _hover: {
        color: "black",
        background: "shade"
    },

    _active: {
        color: "black",
        background: "shade"
    }
});

const lightVariant = defineStyle((props) => ({
    border: `solid 1px`,
    borderColor: "divider.500",
    borderRadius: ".2em",
    bg: "#faf6ed",
    color: "black",
    boxShadow: `inset -2px -2px 15px -7px ${rgba(0, 0, 0, .3)}`,
    _focusVisible: {
        bg: null,
    },
    _active: {
        ...shadedVariant(props),
    }
}));

export const buttonTheme = defineStyleConfig({
    variants: {
        shaded: shadedVariant,
        secondary: secondaryVariant,
        ghost: ghostVariant,
        light: lightVariant,
    },
    sizes: {
        md: {
            px: "3",
            py: "2",
            h: "unset",
        },
        sm: {
            px: "3",
            py: "1",
            h: "unset"
        }
    },
    defaultProps: {
        variant: "shaded",
        colorScheme: "primary",
    },
    baseStyle: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        gap: 1,
    }
});
