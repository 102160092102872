import {createFileRoute} from '@tanstack/react-router'
import {getAPI} from "../../../../../api/api";
import {ListResponse} from "../../../../../models/response";
import {XwgWithId} from "../../../../../models/xwg";
import {XwgSearchQuery} from "../../../../../models/xwgSearchQuery";

export const Route = createFileRoute('/_site/admin/approving/_queue')({
    beforeLoad: async (): Promise<{query: XwgSearchQuery}> => {
        return {
            query: {
                filter: {
                    publish_status: ["new", "hold"],
                    image_types: ["primary", "other", "private"],
                },
                display: {
                    attributes: ["id", "name", "version", "images", "created_by", "tags", "publish_status", "created", "hold", "note"],
                    image_attributes: ["id", "url", "name", "user", "type", "created"]
                }
            }
        }
    },
    loader: async ({context}) => {
        const api = getAPI(context);
        const resp = await api.post<ListResponse<XwgWithId>, XwgSearchQuery>("/api/v2/xwg/query", context.query);

        return {
            ...resp.data
        }
    }
});
