import {createFileRoute} from "@tanstack/react-router";
import {useTranslation} from "../../../../utils/helpers";
import React from "react";
import {Columns} from "../../../../components/generic/columns";
import {CwgSearchResult} from "../../../../components/cwg/cwgSearchResult";
import {CwgSearchForm} from "../../../../components/cwg/cwgSearchForm";
import {combineTitle} from "../../../../utils/combineTitle";
import {getFixedT} from "../../../../utils/getFixedT";
import {XwgQueryResponse} from "../../../../models/xwg";
import {SearchHint} from "../../../../components/cwg/searchHint";
import {TagCategory} from "../../../../models/tag";
import {Route as LayoutRoute} from "./route";
import {Route as SearchWithQueryRoute} from "./$query";
import {UserSearch} from "../../../../models/user";
import {Route as UserSearchResult} from "../../users/search/$username";
import {UserSearchForm} from "../../../../components/users/searchForm";
import {XwgFilter} from "../../../../models/xwgSearchQuery";

export const Route = createFileRoute('/_site/cwg/search/')({
    component: () => {
        const {tagCategories} = LayoutRoute.useLoaderData() ?? {tagCategories: []};

        const search = Route.useSearch();
        return <CwgSearch
            tagCategories={tagCategories}
            filter={search.filter}
        />
    },
    validateSearch: (search: Record<string, unknown>) => {
        return {
            filter: (search.filter ?? {}) as XwgFilter
        }
    },
    meta: ({matches}) => {
        const t = getFixedT("layout")
        return combineTitle(matches, t("Search"))
    },
});

export function CwgSearch({tagCategories,  result, offset, limit, onOffsetChange, filter = {}}: {
    tagCategories: TagCategory[],
    offset?: number,
    limit?: number,
    filter?: XwgFilter,
    result?: XwgQueryResponse | null,
    onOffsetChange?: (newOffset: number) => void
}) {
    const {t: tb} = useTranslation("browse");
    const {t: ts} = useTranslation("search");

    const navigate = Route.useNavigate();

    return (
        <Columns>
            <>
                <h3>{tb("Search the catalogue")}</h3>
                <CwgSearchForm
                    tagCategories={tagCategories}
                    filter={filter}
                    onSearch={async ({names, ...filter}: XwgFilter) => {
                        if (!(names?.[0])) {
                            return;
                        }

                        await navigate({
                            to: SearchWithQueryRoute.fullPath,
                            params: {
                                query: names?.[0] as string
                            },
                            search: {
                                offset: 0,
                                limit: limit ?? 100,
                                filter: filter
                            }
                        });
                    }}
                />

                <h3>{ts("User search")}</h3>
                <UserSearchForm
                    onSearch={async (search: UserSearch) => {
                        await navigate({
                            to: UserSearchResult.fullPath,
                            params: {
                                username: search.username
                            }
                        })
                    }}
                />
            </>
            {result
                ? <>
                    <h3>{ts("Search result")}</h3>
                    <CwgSearchResult
                        search={result}
                        offset={offset}
                        limit={limit}
                        onOffsetChange={onOffsetChange}
                    />
                </>
                : <SearchHint/>
            }
        </Columns>
    );
}
