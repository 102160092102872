import React, {useMemo} from "react";
import {XwgQueryResponse, XwgWithId} from "../../models/xwg";
import Pagination from "../generic/pagination";
import {CwgCatalogueItem} from "./catalogueItem";
import {LightSelect} from "../generic/select";
import {useMatch, useNavigate} from "@tanstack/react-router";
import {useTranslation} from "../../utils/helpers";
import {XwgFilter} from "../../models/xwgSearchQuery";
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    chakra,
    Flex,
    ListProps,
    UnorderedList
} from "@chakra-ui/react";
import _ from "lodash";

export const CwgCatalogueBase = ({children, ...props}: ListProps) => <UnorderedList
    listStyleType={"none"}
    display={"grid"}
    gridTemplateColumns={"repeat(auto-fill, minmax(85px, 1fr))"}
    gridColumnGap={"1em"}
    gridRowGap={"2em"}
    padding={0}
    margin={"2.5em 0"}
    {...props}
>
    {children}
</UnorderedList>;

export const CwgCatalogueItems = ({items}: {items: XwgWithId[]}) => {
    return <CwgCatalogueBase>
        {items.map((xwg) => <CwgCatalogueItem key={xwg.id} xwg={xwg} />)}
    </CwgCatalogueBase>
}

export const CwgCataloguePlaceholders = ({count}: {count: number}) => {
    return <CwgCatalogueBase>
        {Array.from({length: count}).map((_, i) => <CwgCatalogueItem key={i} />)}
    </CwgCatalogueBase>;
}

const CwgCatalogueHeader = ({children, ...props}: BoxProps) => <Box
    as={"header"}
    mt={6}
    mb={6}
    fontWeight={"bold"}
    pl={4}
    pr={4}
    fontSize={".85em"}
    {...props}
>
    {children}
</Box>;

export type AdditionalFilter = {
    filter: XwgFilter,
    name: string | React.ReactNode
}

export type AdditionalView = {
    name: string | React.ReactNode,
    view: React.ComponentType<{items: XwgWithId[]}>,
    id: string
}

export type CwgCatalogueProps = {
    additionalFilters?: AdditionalFilter[];
    onFilterChange?: (newFilter: XwgFilter) => void | Promise<void>;
    activeFilter?: XwgFilter;
    view?: AdditionalView;
    additionalViews?: AdditionalView[];
    onViewChange?: (newView: AdditionalView) => void | Promise<void>;
}

export function CwgCatalogue({
    additionalFilters,
    onFilterChange,
    activeFilter,
    view,
    additionalViews,
    onViewChange,
}: CwgCatalogueProps) {
    const {t} = useTranslation("browse");

    const match = useMatch({strict: false});
    const navigate = useNavigate();

    const {order, direction, offset, limit} = match.search as {order: string, direction: string, offset: number, limit: number};
    const {xwgs} = (match.loaderData as {xwgs: XwgQueryResponse}) ?? {xwgs: null};

    if (xwgs && xwgs.items) {
        xwgs.items = xwgs.items.map((xwg) => (xwg instanceof XwgWithId) ? xwg : new XwgWithId(xwg));
    }

    if (!view) {
        view = {
            name: "",
            view: CwgCatalogueItems,
            id: "catalogue"
        }
    }

    const ViewComponent = view.view;
    const memoizedView = useMemo(() => xwgs?.items && <ViewComponent items={xwgs.items} />, [xwgs?.items]);

    return <>
        {additionalFilters && <ButtonGroup isAttached variant={"secondary"} size={"sm"} mt={4} display={"flex"} justifyContent={"center"}>
            {additionalFilters.map(({filter, name}, idx) => (
                <Button
                    key={idx}
                    onClick={() => {
                        onFilterChange?.(filter);
                    }}
                    isActive={_.isEqual(filter, activeFilter)}
                >
                    {name}
                </Button>
            ))}
        </ButtonGroup>}

        {additionalViews && <ButtonGroup isAttached variant={"secondary"} size={"sm"} mt={4} display={"flex"} justifyContent={"center"}>
            {additionalViews?.map((v) => (
                <Button
                    key={v.id}
                    onClick={() => {
                        onViewChange?.(v);
                    }}
                    isActive={v.id === view.id}
                >
                    {v.name}
                </Button>
            ))}
        </ButtonGroup>}

        <CwgCatalogueHeader>
            <Flex
                gap={4}
                alignItems={"baseline"}
                flexWrap={"wrap"}
                justifyContent={"space-between"}
            >
                <Box
                    whiteSpace={"nowrap"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                >
                    {t("Sort:")}
                    <LightSelect
                        instanceId={"sort"}
                        isClearable={false}
                        isSearchable={false}
                        options={[
                            {value: "name", label: t("Name")},
                            {value: "cat_no", label: t("Cat. no.")},
                            {value: "created", label: t("Created")},
                            {value: "updated", label: t("Updated")},
                            {value: "published", label: t("Published")},
                        ]}
                        value={order}
                        onChange={(newSortColumn) => {
                            navigate({
                                to: match.fullPath,
                                search: (prev: Record<string, string>) => {
                                    if (!prev) {
                                        prev = {};
                                    }

                                    prev.order = newSortColumn as string;
                                    return prev;
                                }
                            });
                        }}
                    />
                    <LightSelect
                        instanceId={"direction"}
                        isClearable={false}
                        isSearchable={false}
                        options={[
                            {value: "ASC", label: t("Ascending")},
                            {value: "DESC", label: t("Descending")},
                        ]}
                        value={direction}
                        onChange={(newSortDirection) => {
                            navigate({
                                to: match.fullPath,
                                search: (prev: Record<string, string>) => {
                                    prev.direction = newSortDirection as string;
                                    return prev;
                                }
                            })
                        }}
                    />
                </Box>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                >
                    {t("Number of items on page:")}
                    <LightSelect
                        instanceId={"limit"}
                        isClearable={false}
                        isSearchable={false}
                        options={[
                            {value: "36", label: "36 (4x9)"},
                            {value: "54", label: "54 (6x9)"},
                            {value: "99", label: "99 (11x9)"},
                        ]}
                        value={limit.toString()}
                        onChange={(newLimit) => {
                            if (!newLimit) {
                                return;
                            }

                            navigate({
                                to: match.fullPath,
                                search: (prev: Record<string, string>) => {
                                    prev.limit = newLimit as string;
                                    return prev;
                                }
                            });
                        }}
                    />
                </Box>

                <Box>
                    {(xwgs && xwgs.count) ? t("Showing {{from}}-{{to}} of {{total}} items matching given filtering.", {
                        from: offset + 1,
                        to: Math.min(offset + limit, xwgs.count),
                        total: xwgs.count
                    }) : t("{{total}} items matching given filtering.", {total: 0})}
                </Box>
            </Flex>
        </CwgCatalogueHeader>

        <chakra.main
            pb={8}
        >
            {xwgs ? (
                xwgs.count
                    ? <>
                        {memoizedView}
                        <Pagination
                            offset={offset}
                            limit={limit}
                        count={xwgs.count}
                        onOffsetChange={(newOffset) => {
                            navigate({
                                to: match.fullPath,
                                search: (prev: Record<string, string>) => {
                                    if (!prev) {
                                        prev = {};
                                    }

                                    prev.offset = newOffset.toString();
                                    return prev;
                                }
                            });
                        }}
                    />
                </>
                : <Alert>
                    <AlertIcon />
                    <AlertDescription>{t("No items match current filter settings.")}</AlertDescription>
                </Alert>
            ) : <CwgCataloguePlaceholders count={limit} />}
        </chakra.main>
    </>;
}
