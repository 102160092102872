import {createFileRoute, Link} from '@tanstack/react-router'
import {useTranslation} from "../../../utils/helpers";
import React from "react";
import Account from "../../../components/account/account";
import {getAPI, useAPI} from "../../../api/api";
import {UserTag} from "../../../models/tag";
import {Alert, AlertIcon, Divider, HStack, Tag, TagCloseButton, TagLabel, Tooltip, useToast} from "@chakra-ui/react";
import {Route as CwgBrowseAdvanced} from "../cwg/browse/advanced/$filter";
import {useAlert} from "../../../components/generic/alert";
import {AddTagForm} from "../../../components/cwg/addTagForm";

export const Route = createFileRoute('/_site/cwg-collection/tags')({
    component: UserTagsManager,
    loader: async ({context}) => {
        const api = getAPI(context);
        const tags = await api.get<UserTag[]>("/api/v2/tags/user/me");

        return {
            tags: tags.data
        }
    }
});

function UserTagsManager() {
    const {t} = useTranslation("tags");
    const {tags: initialTags} = Route.useLoaderData();

    const [tags, setTags] = React.useState(initialTags);

    const {t: to} = useTranslation("cwg_ops");
    const {showConfirm} = useAlert();
    const api = useAPI();
    const toast = useToast();

    return (
        <Account title={t("Tags")}>
            <Alert status={"info"}>
                <AlertIcon />
                {t("Here you can find list of all your tags that you assigned to some xWGs. By clicking the tag, you can filter xWGs labeled with this tag.")}
            </Alert>

            <HStack wrap={"wrap"} spacing={"6"} mt={"4"}>
                {tags.map((tag) => (
                    <Link to={CwgBrowseAdvanced.fullPath} params={{filter: {tags:[tag.id]}}} key={tag.id}>
                        <Tag variant={"tag"}>
                            <TagLabel>{tag.name} ({tag.count})</TagLabel>
                            <Tooltip label={t("Remove tag")}>
                                <TagCloseButton onClick={async (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();

                                    if (await showConfirm(to("Are you sure?"), t("Removing tag will clear it from all xWGs. Do you want to continue?")) === "yes") {
                                        await api.delete(`/api/v2/tags/user/me/${encodeURIComponent(tag.id)}`);
                                        const tags = await api.get<UserTag[]>("/api/v2/tags/user/me");
                                        setTags(tags.data);

                                        toast({
                                            title: t("Tag has been removed."),
                                            status: "success",
                                        });
                                    }
                                }} />
                            </Tooltip>
                        </Tag>
                    </Link>
                ))}
            </HStack>

            <Divider mt={"6"} />

            <AddTagForm
                size={"md"}
                style={{
                    maxWidth: "15em"
                }}
                onCreate={async (name) => {
                    await api.post<UserTag[]>("/api/v2/tags/user/me", [name]);

                    const tags = await api.get<UserTag[]>("/api/v2/tags/user/me");
                    setTags(tags.data);

                    toast({
                        title: t("Tag has been created."),
                        status: "success",
                    });
                }}
            />
        </Account>
    );
}
