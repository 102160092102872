import {FormButtons} from "../generic/form";
import {Button} from "../generic/buttons";
import Error from "../error";
import {useTranslation} from "../../utils/helpers";
import React from "react";
import {chakra, FormControl, Input} from "@chakra-ui/react";

export type LoginFormProps = {
    client_id: string;
    state?: string | null;
    redirect_uri: string;
    error?: string;
}


export default function LoginForm({client_id, state, error, redirect_uri}: LoginFormProps) {
    const {t} = useTranslation("account");

    return <>
        <h2>{t("Sign in")}</h2>
        <chakra.form
            action={"/oauth2/authorize"}
            method={"post"}
            maxW={"20em"}
            mx={"auto"}
        >
            <input type={"hidden"} name={"client_id"} value={client_id} />
            {state && <input type={"hidden"} name={"state"} value={state} />}
            <input type={"hidden"} name={"response_type"} value={"code"} />
            {redirect_uri && <input type={"hidden"} name={"redirect_uri"} value={redirect_uri} />}

            {error && <Error>{error}</Error>}

            <FormControl>
                <Input placeholder={t("User name")} name={"username"}/>
            </FormControl>
            <FormControl>
                <Input placeholder={t("Password")} name={"password"} type={"password"}/>
            </FormControl>

            <FormButtons>
                <Button type={"submit"}>{t("Sign in")}</Button>
            </FormButtons>
        </chakra.form>
    </>
}
