import {createFileRoute} from '@tanstack/react-router'
import {thirdLevelIndexRoute} from "../../../../../../../../utils/collectionListing";
import React from "react";
import {CwgCatalogue} from "../../../../../../../../components/cwg/cwgCatalogueView";
import {useTranslation} from "../../../../../../../../utils/helpers";
import {XwgFilter} from "../../../../../../../../models/xwgSearchQuery";
import _ from "lodash";

export const Route = createFileRoute('/_site/users/_public_profile/collection/$username/$primarySorting/$primaryCategoryName/$secondaryCategoryName/')({
    ...thirdLevelIndexRoute({
        validateSearch: (search: Record<string, string>) => {
            return {
                filter: (search.filter ?? {}) as XwgFilter
            }
        },
        beforeLoad: async ({context: {filter, ...restOfContext}, search}) => {
            filter = _.mergeWith({}, filter, search.filter, (objValue, srcValue) => {
                if (Array.isArray(objValue) && Array.isArray(srcValue)) {
                    return [...objValue, ...srcValue];
                }
            });

            return {
                ...restOfContext,
                filter
            }
        },
        component: () => {
            const {t} = useTranslation("browse");
            const navigate = Route.useNavigate();
            const search = Route.useSearch();

            return <CwgCatalogue
                additionalFilters={[
                    {
                        name: t("All"),
                        filter: {},
                    },
                    {
                        name: t("Not collected by me only"),
                        filter: {
                            collected: [false]
                        }
                    },
                    {
                        name: t("Only what I want"),
                        filter: {
                            wanted: [true]
                        }
                    },
                ]}
                activeFilter={search.filter}
                onFilterChange={async (newFilter) => {
                    await navigate({
                        search: (prev) => ({
                            ...prev,
                            filter: newFilter
                        })
                    })
                }}
            />
        }
    })
});
