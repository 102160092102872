import {createFileRoute} from '@tanstack/react-router'
import {thirdLevelIndexRoute} from "../../../../../../../utils/collectionListing";
import React from "react";
import {CwgCatalogue, CwgCatalogueItems} from "../../../../../../../components/cwg/cwgCatalogueView";
import {useTranslation} from "../../../../../../../utils/helpers";
import {TableWithImagesView} from "../../../../../../../components/cwg/tableWithImagesView";
import {OverviewTableView} from "../../../../../../../components/cwg/overviewTableView";

export const Route = createFileRoute('/_site/cwg-collection/browse/$primarySorting/$primaryCategoryName/$secondaryCategoryName/')({
    ...thirdLevelIndexRoute({
        validateSearch: (search: Record<string, string>) => {
            const views = ["catalogue", "images", "table"];

            return {
                mode: (views.find((v) => v === search.mode) ?? views[0])
            }
        },
        component: () => {
            const {t} = useTranslation("browse");
            const {mode} = Route.useSearch();
            const navigate = Route.useNavigate();

            const views = [
                {
                    name: t("Catalogue list"),
                    view: CwgCatalogueItems,
                    id: "catalogue"
                },
                {
                    name: t("Table with images"),
                    view: TableWithImagesView,
                    id: "images",
                },
                {
                    name: t("Overview table"),
                    view: OverviewTableView,
                    id: "table"
                }
            ];
            const view = views.find((v) => v.id === mode);

            return <CwgCatalogue
                additionalViews={views}
                onViewChange={async (newView) => {
                    await navigate({
                        search: (prev) => ({
                            ...prev,
                            mode: newView.id
                        })
                    })
                }}
                view={view}
            />;
        },
        additionalDisplayOptions: ({search: {mode}}) => {
            switch (mode) {
                case "images":
                case "table":
                    return {
                        "attributes": ["collection_info"]
                    }

                default:
                    return {};
            }
        }
    })
});
