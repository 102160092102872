import {useLocation} from "@tanstack/react-router";
import React from "react";
import {useTranslation} from "../../utils/helpers";
import {useCurrentUser} from "../../models/user";
import {NotFound} from "../error";

import {Route as RouteMessages} from "../../routes/_site/messages/route";
import {Route as UserProfileRoute} from "../../routes/_site/users/_public_profile/index.$username";
import {Route as ChangePasswordRoute} from "../../routes/_site/change-password";
import {Route as ProfileRoute} from "../../routes/_site/users/profile";
import {Route as CwgOwnRoute} from "../../routes/_site/cwg/own";
import {Route as CwgDuplicatesRoute} from "../../routes/_site/cwg-collection/duplicates";
import {Route as CwgTagsRoute} from "../../routes/_site/cwg-collection/tags";
import {Box, TabPanels, Tabs} from "@chakra-ui/react";
import {CollapsibleTabList} from "../generic/tabs";

const Account = ({title, children}: {title?: string, children?: React.ReactNode}) => {
    const {t} = useTranslation(["settings", "messages", "cwg_ops", "browse", "tags", "import", "export", "profile"]);

    const currentUser = useCurrentUser();
    const location = useLocation();

    if (!currentUser) {
        return <NotFound />;
    }

    const tabs = [
        {
            to: RouteMessages.fullPath,
            label: <>{t("Private messages", {ns: "messages"})}</>
        },
        {
            to: ChangePasswordRoute.fullPath,
            label: t("Change password")
        },
        {
            to: ProfileRoute.fullPath,
            label: t("Profile settings")
        },
        {
            to: CwgOwnRoute.fullPath,
            label: t("xWGs entered by me", {ns: "cwg_ops"})
        },
        {
            to: CwgDuplicatesRoute.fullPath,
            label: t("Duplicates", {ns: "browse"})
        },
        {
            to: CwgTagsRoute.fullPath,
            label: t("Tags", {ns: "tags"})
        },
        /*{
            to: ImportRoute.fullPath,
            label: <>{t("Import", {ns: "import"})} <Todo /></>
        },
        {
            to: ExportRoute.fullPath,
            label: <>{t("Export", {ns: "export"})} <Todo /></>
        },*/
        {
            to: UserProfileRoute.fullPath,
            params: {username: currentUser.name},
            label: t("Public profile", {ns: "profile"})
        }
    ];

    return <>
        {title && <h2>{title}</h2>}
        <Tabs index={tabs.findIndex(tab => location.href.startsWith(tab.to))}>
            <CollapsibleTabList tabs={tabs} />
            <TabPanels>
                <Box p={4}>
                    {children}
                </Box>
            </TabPanels>
        </Tabs>
    </>;
}

export default Account;
