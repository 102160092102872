import React, {createContext, useContext, useState} from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    ButtonGroup
} from "@chakra-ui/react";
import {Button} from "./buttons";
import {useTranslation} from "../../utils/helpers";

type AlertContextType = {
    showAlert: (title: string, message: string) => Promise<void>;
    showConfirm: (title: string, message: string) => Promise<"yes" | "no">;
};

const AlertContext = createContext<AlertContextType>({
    showAlert: async () => {
        throw new Error("useAlert can be used only inside AlertProvider context.")
    },
    showConfirm: async () => {
        throw new Error("useAlert can be used only inside AlertProvider context.")
    }
});

export function useAlert() {
    return useContext(AlertContext);
}

type ButtonType = {
    text: string;
    resolve?: string;
    onClick?: () => void;
    isDefault?: boolean;
}

export function AlertProvider({children}: {children?: React.ReactNode}) {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const cancelRef = React.useRef<HTMLButtonElement>(null);

    const [title, setTitle] = useState<string | null>(null);
    const [message, setMessage] = useState<string>("");
    const [buttons, setButtons] = useState<ButtonType[]>([]);
    const [resolve, setResolve] = useState<((reason?: string) => void) | (() => void)>();

    const {t} = useTranslation("cwg_ops");

    const alertContext = {
        showAlert: async (title: string, message: string): Promise<void> => {
            return new Promise<void>((resolve) => {
                setResolve(() => resolve);
                setTitle(title);
                setMessage(message);
                setButtons([
                    {
                        text: "OK",
                        isDefault: true
                    }
                ]);
                setIsDialogOpen(true);
            });
        },
        showConfirm: async (title: string, message: string): Promise<"yes" | "no"> => {
            return new Promise<"yes" | "no">((resolve) => {
                setResolve(() => resolve as (reason?: string) => void);
                setTitle(title);
                setMessage(message);
                setButtons([
                    {
                        text: t("Yes"),
                        resolve: "yes"
                    },
                    {
                        text: t("No"),
                        resolve: "no",
                        isDefault: true
                    }
                ]);
                setIsDialogOpen(true);
            });
        },
    }

    return <AlertContext.Provider value={alertContext}>
        {children}
        <AlertDialog
            isOpen={isDialogOpen}
            onClose={() => {
                setIsDialogOpen(false);
            }}
            leastDestructiveRef={cancelRef}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    {title && <AlertDialogHeader>
                        {title}
                    </AlertDialogHeader>}
                    <AlertDialogBody>
                        {message}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <ButtonGroup>
                            {buttons.map((button, idx) => (
                                (button.isDefault)
                                    ? (
                                        <Button
                                            type={"button"}
                                            ref={cancelRef}
                                            key={idx}
                                            onClick={(): void => {
                                                if (button.resolve) {
                                                    if (resolve) {
                                                        resolve(button.resolve);
                                                    }
                                                } else {
                                                    if (resolve) {
                                                        resolve();
                                                    }
                                                }
                                                setIsDialogOpen(false);
                                            }}
                                        >
                                            {button.text}
                                        </Button>
                                    )
                                    : (
                                        <Button
                                            key={idx}
                                            onClick={(): void => {
                                                if (button.resolve) {
                                                    if (resolve) {
                                                        resolve(button.resolve);
                                                    }
                                                } else {
                                                    if (resolve) {
                                                        resolve();
                                                    }
                                                }
                                                setIsDialogOpen(false);
                                            }}
                                        >
                                            {button.text}
                                        </Button>
                                    )
                            ))}
                        </ButtonGroup>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </AlertContext.Provider>
}
