import React from "react";
import {chakra, BoxProps} from "@chakra-ui/react";

export const PageContainer = function({children, ...props}: BoxProps){
    return <chakra.main
        {...props}
        w={"100%"}
        maxW={"var(--chakra-breakpoints-lg)"}
        ml={"auto"}
        mr={"auto"}
        bg={"shade"}
        borderRadius={{base: "0", lg: "lg"}}
        py={3}
        px={4}
    >
        {children}
    </chakra.main>
}
