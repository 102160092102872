import {createFileRoute, Link} from '@tanstack/react-router'
import {Breadcrumb, HeaderWithBreadcrumb} from "../../../../components/generic/breadcrumb";
import {useTranslation} from "../../../../utils/helpers";
import React, {useCallback, useEffect, useState} from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel, Alert, AlertDescription, AlertIcon, Badge,
    Box,
    BreadcrumbItem,
    BreadcrumbLink, ButtonGroup, FormControl, FormLabel,
    Heading, HStack, Input, Stack, Switch,
    Table,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr, Wrap, WrapItem
} from "@chakra-ui/react";
import {Route as AdminIndexRoute} from "../index";
import {Controller, useForm} from "react-hook-form";
import {ListResponse} from "../../../../models/response";
import {Group, User, UserFilter, UserRequest} from "../../../../models/user";
import {getAPI} from "../../../../api/api";
import {RISON} from "rison2";
import Pagination from "../../../../components/generic/pagination";
import {FormattedDate} from "../../../../components/generic/date";
import Select from "../../../../components/generic/select";
import {ErrorComponent} from "../../../../components/error";
import {TokenScope, TokenScopeModel} from "../../../../models/tokenScope";
import {FormButtons} from "../../../../components/generic/form";
import {Button} from "../../../../components/generic/buttons";
import KeyIcon from "~icons/twemoji/key";
import LockIcon from "~icons/flat-color-icons/lock";
import GroupIcon from "~icons/icon-park/family";

import {Route as PrivilegesRoute} from "./$userId.privileges";
import {Route as GroupsRoute} from "./$userId.groups";
import {NotFoundError} from "../../../../utils/notFoundError";

export const Route = createFileRoute('/_site/admin/users/')({
    validateSearch: (search: Record<string, string>) => {
        return {
            filter: typeof(search.filter) === "object" ? search.filter : RISON.parse(search.filter ?? "()"),
            offset: parseInt(search.offset) || 0,
            limit: parseInt(search.limit) || 25
        }
    },
    loaderDeps: ({search}) => search,
    loader: async ({context, deps: search}) => {
        if (!context.currentUser?.currentUser?.has(TokenScope.ADMIN_USERS)) {
            throw new NotFoundError();
        }

        const api = getAPI(context);

        const users = await api.post<ListResponse<User>, UserRequest>("/api/v2/users/query", {
            filter: search.filter ?? {},
            display: {
                limit: search.limit,
                offset: search.offset,
                order: ["name:ASC"],
                attributes: ["id", "name", "registration_date", "last_login", "groups"],
            }
        });

        const scopes = await api.get<ListResponse<TokenScopeModel>>("/api/v2/scopes");

        const groups = await api.get<ListResponse<Group>>("/api/v2/groups?limit=1000&attribute=id&attribute=name");

        return {
            users: users.data,
            scopes: scopes.data.items,
            groups: groups.data.items,
        }
    },
    errorComponent: ErrorComponent,
    component: () => {
        const {t} = useTranslation("admin_users");

        type UserFilterFormData = {
            search: string;
            groups: number[];
            scopes: string[];
            needAllScopes: boolean;
        };

        const toFormData = useCallback((filter: UserFilter): UserFilterFormData => ({
            search: filter.search?.[0] ?? "",
            groups: filter.groups ?? [],
            scopes: filter.scopes ?? [],
            needAllScopes: filter.scope_match_mode === "all"
        }), []);

        const fromFormData = useCallback((data: UserFilterFormData): UserFilter => ({
            search: data.search ? [data.search] : [],
            groups: data.groups ?? [],
            scopes: data.scopes ?? [],
            scope_match_mode: data.needAllScopes ? "all": "any",
        }), []);

        const search = Route.useSearch();
        const {register, handleSubmit, control} = useForm<UserFilterFormData>({
            defaultValues: toFormData(search.filter)
        });
        const {users, scopes, groups} = Route.useLoaderData();
        const navigate = Route.useNavigate();

        const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | undefined>(undefined);
        useEffect(() => {
            setMenuPortalTarget(document.body);
        }, []);

        const setFilter = async (filter: UserFilterFormData) => {
            await navigate({
                search: (search) => ({
                    ...search,
                    filter: fromFormData(filter),
                    offset: 0
                })
            })
        }

        return <>
            <HeaderWithBreadcrumb>
                <h2>{t("Users")}</h2>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={AdminIndexRoute.fullPath}>{t("Administration", {ns: "admin"})}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={Route.fullPath}>{t("Users")}</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HeaderWithBreadcrumb>

            <Accordion
                allowMultiple
                mb={4}
            >
                <AccordionItem>
                    <Heading as={"h3"} m={0} size={"sm"}>
                        <AccordionButton>
                            <Box flex={"1"} as={"span"} textAlign={"left"}>
                                {t("Filter")}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </Heading>
                    <AccordionPanel>
                        <form onSubmit={handleSubmit(setFilter)}>
                            <FormControl>
                                <FormLabel>{t("Username")}</FormLabel>
                                <Input type={"text"} {...register("search")} />
                            </FormControl>
                            <Wrap>
                                <WrapItem flex={"1"}>
                                    <FormControl>
                                        <FormLabel>{t("Belonging to groups:")}</FormLabel>
                                        <Controller
                                            control={control}
                                            name={"groups"}
                                            render={({field}) => (
                                                <Select
                                                    instanceId={field.name}
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    isMulti
                                                    menuPortalTarget={menuPortalTarget}
                                                    options={groups.map(group => ({label: group.name, value: group.id}))}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </WrapItem>

                                <WrapItem flex={"1"}>
                                    <FormControl>
                                        <FormLabel>{t("Has privileges:")}</FormLabel>
                                        <HStack>
                                            <Box flex={"1"}>
                                                <Controller
                                                    control={control}
                                                    name={"scopes"}
                                                    render={({field}) => (
                                                        <Select
                                                            instanceId={field.name}
                                                            name={field.name}
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            isMulti
                                                            menuPortalTarget={menuPortalTarget}
                                                            options={scopes.map(scope => ({label: scope.id, value: scope.id}))}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                            <HStack>
                                                <Text>{t("Any")}</Text>
                                                <Switch {...register("needAllScopes")} />
                                                <Text>{t("All")}</Text>
                                            </HStack>
                                        </HStack>
                                    </FormControl>
                                </WrapItem>
                            </Wrap>

                            <FormButtons>
                                <Button type={"submit"}>{t("Apply")}</Button>
                            </FormButtons>
                        </form>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>

            <Table>
                <Thead>
                    <Tr>
                        <Th>{t("Username")}</Th>
                        <Th>{t("Groups")}</Th>
                        <Th className={"td-shrink"}>{t("Last login")}</Th>
                        <Th className={"td-shrink"}>{t("Registered")}</Th>
                        <Th className={"td-shrink"}></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {users.items.map((user) => <Tr key={user.id}>
                        <Td>
                            {user.name}
                        </Td>
                        <Td>
                            <Stack direction={"row"} flexWrap={"wrap"}>{user.groups.map(group => <Badge key={group.id}>{group.name}</Badge>)}</Stack>
                        </Td>
                        <Td className={"td-shrink text-right"}>
                            {user.last_login ? <FormattedDate date={user.last_login} /> : <Text color={"gray"}>{t("Never")}</Text>}
                        </Td>
                        <Td className={"td-shrink text-right"}>
                            <FormattedDate date={user.registration_date} />
                        </Td>
                        <Td className={"td-shrink"}>
                            <ButtonGroup isAttached variant={"secondary"} size={"sm"}>
                                <Button
                                    icon={KeyIcon}
                                    onClick={async () => await navigate({
                                        to: PrivilegesRoute.fullPath,
                                        params: {
                                            userId: user.id
                                        },
                                        search: search
                                    })}
                                >
                                    {t("Privileges")}
                                </Button>
                                <Button
                                    icon={GroupIcon}
                                    onClick={async () => await navigate({
                                        to: GroupsRoute.fullPath,
                                        params: {
                                            userId: user.id
                                        },
                                        search: search
                                    })}
                                >
                                    {t("Groups")}
                                </Button>
                                <Button
                                    icon={LockIcon}
                                    isDisabled
                                >
                                    {t("Block")}
                                </Button>
                            </ButtonGroup>
                        </Td>
                    </Tr>)}
                    {users.items.length === 0 ? <Tr>
                        <Td colSpan={5}>
                            <Alert>
                                <AlertIcon />
                                <AlertDescription>{t("No users matching specified filter.")}</AlertDescription>
                            </Alert>
                        </Td>
                    </Tr> : null}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Td colSpan={5}>
                            <Pagination
                                count={users.count}
                                limit={search.limit}
                                offset={search.offset}
                                onOffsetChange={async (newOffset) => {
                                    await navigate({
                                        search: (search) => ({
                                            ...search,
                                            offset: newOffset
                                        })
                                    })
                                }}
                            />
                        </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </>
    }
});
