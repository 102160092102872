import {createFileRoute, Link, Outlet, useMatches} from '@tanstack/react-router'

import React from "react";
import {useTranslation} from "../../../../utils/helpers";
import {Route as RoutePrimarySorting} from "./$primarySorting/index";
import {ContentHeader} from "../../../../components/contentHeader";
import {Button} from "@chakra-ui/react";

import {zeroLevelBeforeLoad} from "../../../../utils/collectionListing";
import {findInMatchesData} from "../../../../utils/findInMatchesData";
import {TagCategory} from "../../../../models/tag";
import {zeroLevelRouteLoader} from "../../../../utils/collectionListing/zero";

export const Route = createFileRoute('/_site/cwg-collection/browse')({
    component: CwgBrowseHeader,
    beforeLoad: async ({context}) => {
        return {
            ...(await zeroLevelBeforeLoad(context, {collected: true})),
            isAdvanced: false
        }
    },
    loader: zeroLevelRouteLoader,
});

function CwgBrowseHeader() {
    const {t} = useTranslation("browse");
    const {tagCategories} = Route.useLoaderData();
    const matches = useMatches();
    const primaryCategory = findInMatchesData<TagCategory>(matches, "primaryCategory");

    return <>
        <ContentHeader
            title={t("My xWG collection")}
            toolbarTitle={t("Primary sorting:")}
            toolbarButtons={[
                ...tagCategories.map(category =>
                    <Button
                        size={"sm"}
                        key={category.id}
                        as={Link}
                        to={RoutePrimarySorting.fullPath}
                        params={{
                            primarySorting: `by-${category.id}`
                        }}
                        isActive={primaryCategory?.id === category.id}
                    >
                        {category.name}
                    </Button>
                ),
            ]}
        />
        <Outlet />
    </>
}
