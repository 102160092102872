import React, {FormEvent, forwardRef} from "react";
import {ErrorMessage as HookErrorMessage} from "@hookform/error-message";
import {Controller as RHKController, ControllerProps, FieldPath, FieldValues, RefCallBack} from "react-hook-form";
import {
    Alert, AlertDescription, AlertIcon,
    ButtonGroup,
    ButtonGroupProps,
    chakra,
    HTMLChakraProps,
    Switch as ChakraSwitch,
    SwitchProps
} from "@chakra-ui/react";
import {cx} from "@emotion/css";

const FieldSet = function({legend, children, ...props}: HTMLChakraProps<"fieldset"> & {legend?: string}) {
    return <chakra.fieldset
        {...props}
        border={"none"}
        padding={"0 0 1em"}
        {... legend ? {
            borderTop: "solid",
            borderTopWidth: "var(--dividerBorderWidth)",
            borderColor: "divider.500",
            pt: ".5em"
        } : {}}
    >
        {legend && <chakra.legend
            fontWeight={"bold"}
            fontSize={"1.1em"}
            paddingLeft={".5em"}
            paddingRight={".5em"}
            marginLeft={"1em"}
        >
            {legend}
        </chakra.legend>}
        {children}
    </chakra.fieldset>;
}

const ErrorMessage = ({className, errors, name, asAlert = false, ...props}: {errors: any, name: string, asAlert?: boolean} & HTMLChakraProps<"span">) => {
    return <HookErrorMessage
        errors={errors}
        name={name}
        render={(args) => {
            const msg = args.message && <chakra.span
                {...props}
                className={cx("ErrorMessage", className)}
                color={asAlert ? undefined : "textDanger"}
                display={"inline-block"}
                mt={".5em"}
                mb={".2em"}
            >
                {args.message}
            </chakra.span>;

            if (asAlert) {
                return <Alert status={"error"}>
                    <AlertIcon />
                    <AlertDescription>{msg}</AlertDescription>
                </Alert>
            }
            return msg;
        }}
    />;
};

const FormButtons = ({children, ...props}: Omit<ButtonGroupProps, "isAttached" | "display" | "justifyContent">) => <ButtonGroup
    isAttached
    display={"flex"}
    justifyContent={"center"}
    mt={4}
    {...props}
>
    {children}
</ButtonGroup>;

const Switch = forwardRef(({onChange, value, ...props}: Omit<SwitchProps, "value" | "isChecked" | "onChange"> & {
    onChange: (value: boolean) => void,
    value: boolean,
}, forwardedRef) => {
    return <ChakraSwitch
        ref={forwardedRef}
        isChecked={value}
        onChange={(e) => onChange(e.currentTarget.checked)}
        {...props}
    />;
});

type InputProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
    value: any,
    disabled?: boolean,
    name: TName,
    ref: RefCallBack,
    onChange: (e: any) => void,
    onBlur: (e: FormEvent<HTMLElement>) => void,
}

export type TransformProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    toInputValue?: (value: any) => TFieldValues[TName],
    fromInputValue?: (value: TFieldValues[TName]) => any,
}

function Controller<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
    P extends InputProps<TFieldValues, TName> = InputProps<TFieldValues, TName>,
    TControl extends React.ComponentType<P> = React.ComponentType<P>
>(
    {toInputValue, fromInputValue, ...props}: TransformProps<TFieldValues, TName> & (
        (ControllerProps<TFieldValues, TName>) |
        (
            Omit<ControllerProps<TFieldValues, TName>, "render"> &
            {Control: TControl} &
            Omit<P, "value" | "disabled" | "name" | "ref" | "onChange" | "onBlur">
        )
    )
) {
    if ((props as {Control?: TControl}).Control) {
        const {
            Control,
            disabled,
            name,
            defaultValue,
            rules,
            shouldUnregister,
            control,
            ...rest
        } = props as Omit<ControllerProps<TFieldValues, TName>, "render"> & {Control: TControl};

        return <RHKController<TFieldValues, TName>
            disabled={disabled}
            name={name}
            defaultValue={defaultValue}
            rules={rules}
            shouldUnregister={shouldUnregister}
            control={control}
            render={({field: {onChange, onBlur, value, ...fieldProps}}) => {
                const finalValue = toInputValue ? toInputValue(value) : value;
                return <Control
                    {...fieldProps}
                    {...rest}
                    value={finalValue}
                    onChange={(e: FormEvent<HTMLInputElement> | TFieldValues[TName]) => {
                        const originalValue: TFieldValues[TName] = (!(typeof(e) === "object" && Object.prototype.hasOwnProperty.call(e, "currentTarget")))
                            ? e as TFieldValues[TName]
                            : ((e as FormEvent<HTMLInputElement>).currentTarget.type === "checkbox"
                                ? (e as FormEvent<HTMLInputElement>).currentTarget.checked
                                : (e as FormEvent<HTMLInputElement>).currentTarget.value
                            ) as TFieldValues[TName];

                        const value = fromInputValue ? fromInputValue(originalValue) : originalValue;
                        onChange(value);
                    }}
                    onBlur={() => onBlur && onBlur()}
                />
            }}
        />
    } else {
        const {render, ...rest} = props as ControllerProps<TFieldValues, TName>;
        return <RHKController<TFieldValues, TName>
            {...rest}
            render={({field: {onChange, onBlur, value, ...fieldProps}, ...props}) => {
                const finalValue = toInputValue ? toInputValue(value) : value;
                return render({
                    ...props,
                    field: {
                        onChange: (value) => {
                            const finalValue = fromInputValue ? fromInputValue(value) : value;
                            onChange(finalValue);
                        },
                        onBlur,
                        value: finalValue,
                        ...fieldProps
                    }
                });
            }}
        />;
    }
}

export {
    FieldSet,
    FormButtons,
    ErrorMessage,
    Controller,
    Switch
};
