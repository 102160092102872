import {XwgExtendedCollection} from "../../models/xwg";
import React, {useCallback, useState} from "react";
import {useAPI} from "../../api/api";
import {CollectionData, updateCollection} from "../../api/updateCollection";
import {CollectionForm} from "./collectionForm";
import {useAlert} from "../generic/alert";
import {ValidationError} from "../../utils/errors";
import {Diagnostics} from "../../models/error";
import {useTranslation} from "../../utils/helpers";

type CollectionFormProps = {
    xwgId: number;
    onUpdateCollection?: (collection: XwgExtendedCollection[]) => void;
    existingCollection?: XwgExtendedCollection[];
}

export const CreateCollectionForm = ({xwgId, onUpdateCollection, existingCollection}: CollectionFormProps) => {
    const [disabled, setDisabled] = useState(false);
    const [errors, setErrors] = useState<Diagnostics>({detail: []});
    const {showConfirm} = useAlert();

    const api = useAPI({flash: "Saving..."});
    const {t} = useTranslation("cwg_index");

    const submitCallback = useCallback(async (data: CollectionData) => {
        setDisabled(true);

        if (existingCollection && existingCollection.some(collection => collection.year == data.year && collection.date == data.date)) {
            const result = await showConfirm(
                t("Overwrite collection?"),
                t("Collection entry with matching year and date already exists. Continuing will overwrite that entry. Do you wish to continue?")
            );

            if (result !== "yes") {
                setDisabled(false);
                return;
            }

        }

        try {
            const newCollection = ((await updateCollection({
                api,
                data,
                xwgId: xwgId
            })) ?? []);

            if (onUpdateCollection) {
                onUpdateCollection(newCollection);
            }
        } catch (e) {
            if (e instanceof ValidationError) {
                setErrors({
                    detail:
                        [
                            ...e.extractErrors(["body"]).detail,
                            ...e.extractErrors(["path"]).detail,
                        ]
                });
            } else {
                console.error(e);
            }
        } finally {
            setDisabled(false);
        }
    }, [xwgId]);

    return <CollectionForm
        onSubmit={submitCallback}
        disabled={disabled}
        errors={errors}
    />;
}
