import {useCurrentUser, User} from "../../models/user";
import {useTranslation} from "../../utils/helpers";
import React from "react";
import {CollapsibleTabList} from "../generic/tabs";

import {Route as UserProfileRoute} from "../../routes/_site/users/_public_profile/index.$username";
import {Route as UserCollectionRoute} from "../../routes/_site/users/_public_profile/collection.$username/index";
import {Route as UserOffersRoute} from "../../routes/_site/users/_public_profile/offers.$username/index";
import {Route as UserWantsRoute} from "../../routes/_site/users/_public_profile/wants.$username/index";
import {Route as UserNotWantRoute} from "../../routes/_site/users/_public_profile/notwant.$username/index";
import {Route as UserMessagesRoute} from "../../routes/_site/users/_public_profile/messages.$username";
import {ContentHeader, ContentHeaderProps} from "../contentHeader";
import {Box, TabPanels, Tabs} from "@chakra-ui/react";


export function PublicProfile({title, toolbar, toolbarTitle, toolbarButtons, children, user, selected}: {children?: React.ReactNode, user: User, selected: string} & ContentHeaderProps) {
    const {t} = useTranslation("profile");
    const currentUser = useCurrentUser();

    if (!user) {
        return null;
    }

    const tabs = [
        {label: t("User profile"), to: UserProfileRoute.fullPath, params: {username: user.name}},
        {label: t("Collection"), to: UserCollectionRoute.fullPath, params: {username: user.name}},
        {label: t("Offers"), to: UserOffersRoute.fullPath, params: {username: user.name}},
        {label: t("Wants"), to: UserWantsRoute.fullPath, params: {username: user.name}},
        {label: t("Don't want"), to: UserNotWantRoute.fullPath, params: {username: user.name}},
        ...currentUser ? [{label: <>{t("Private messages", {ns: "messages"})}</>, to: UserMessagesRoute.fullPath, params: {username: user.name}}] : [],
    ];

    return <>
        <ContentHeader
            title={title}
            toolbar={toolbar}
            toolbarTitle={toolbarTitle}
            toolbarButtons={toolbarButtons}
        />
        <Tabs index={tabs.findIndex(tab => selected == tab.to)}>
            <CollapsibleTabList tabs={tabs} />
            <TabPanels>
                <Box p={4}>
                    {user.private
                        ? <div>{t("User has decided to hide his public profile.")}</div>
                        : children
                    }
                </Box>
            </TabPanels>
        </Tabs>
    </>
}
