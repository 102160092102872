import React, {useCallback, useState} from "react";
import {Image, XwgImageType, XwgWithId} from "../../models/xwg";
import {
    Alert, AlertDescription, AlertIcon,
    Badge,
    Box,
    BreadcrumbItem,
    BreadcrumbLink,
    Button,
    ButtonGroup,
    chakra,
    FormControl,
    FormLabel,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr, useTheme
} from "@chakra-ui/react";
import {useTranslation} from "../../utils/helpers";
import {FormProvider, useForm} from "react-hook-form";
import {useAPI} from "../../api/api";
import {SingleCwgImageUpload} from "./imageUpload";
import {useCurrentUser} from "../../models/user";
import {TokenScope} from "../../models/tokenScope";
import {Dd, Dl, Dt} from "../generic/dl";
import {Trans} from "react-i18next";
import {ProfileLink} from "../users/profileLink";
import {FormattedDate} from "../generic/date";
import Columns from "../generic/columns";
import {CwgHeader} from "./cwgHeader";
import {RemoveButton} from "../generic/buttons";
import Select from "../generic/select";
import {Controller, FormButtons} from "../generic/form";
import {Breadcrumb} from "../generic/breadcrumb";
import {Link} from "@tanstack/react-router";
import {Route as XwgIndexRoute} from "../../routes/_site/cwg/index.$xwgId";
import {Route as XwgImagesRoute} from "../../routes/_site/cwg/index.$xwgId/images";

export function getImageBadge(image: Image, t: ReturnType<typeof useTranslation>["t"]) {
    switch (image.type) {
        case "primary":
            return <Badge colorScheme={"green"}>{t("Primary", {ns: "cwg_ops"})}</Badge>;
        case "other":
            return <Badge colorScheme={"blue"}>{t("Secondary", {ns: "cwg_ops"})}</Badge>;
        case "private":
            return <Badge colorScheme={"red"}>{t("Private", {ns: "cwg_ops"})}</Badge>;
    }
}

export function CwgAdditionalImages({xwg}: {
    xwg: XwgWithId,
}) {
    const {t} = useTranslation("cwg_ops");
    const [images, setImages] = useState<Image[]>(xwg.images ?? []);

    type ImageUpload = {
        image: FileList;
        type: Omit<XwgImageType, "primary">;
        name: string;
    };

    const methods = useForm<ImageUpload>({
        defaultValues: {
            type: "other"
        }
    });
    const {
        register,
        handleSubmit,
        control,
        reset,
    } = methods;

    const api = useAPI();
    const cu = useCurrentUser();

    const reloadImages = useCallback(async () => {
        const resp = await api.get<XwgWithId>(
            `/api/v2/xwg/${xwg.id}`,
            {
                params: {
                    attribute: [
                        "images",
                        "image.id", "image.user", "image.name", "image.url", "image.created", "image.type"
                    ],
                    imageType: [
                        ...cu?.has(TokenScope.CWG_IMAGES) ? ["primary", "other"] : [],
                        "private"
                    ]
                }
            }
        );

        setImages(resp.data.images ?? []);
    }, [api, setImages, xwg]);

    const uploadImage = useCallback(async (image: ImageUpload) => {
        const fd = new FormData();
        fd.set("type", image.type as string);
        fd.append("image", image.image[0]);

        await api.post(
            `/api/v2/xwg/${xwg.id}/image`,
            fd,
            {
                headers: {
                    "Content-Type": undefined,
                }
            }
        );

        reset();

        await reloadImages();
    }, [xwg]);

    const theme = useTheme();

    return <>
        <CwgHeader xwg={xwg} showVersion={true}>
            <Breadcrumb>
                <BreadcrumbItem>
                    <BreadcrumbLink
                        as={Link}
                        to={XwgIndexRoute.fullPath}
                        params={{xwgId: xwg.id}}
                    >
                        {t("{{name}}", {
                            name: xwg.name,
                        })}
                    </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <BreadcrumbLink
                        as={Link}
                        to={XwgImagesRoute.fullPath}
                        params={{xwgId: xwg.id}}
                    >
                        {t("Images")}
                    </BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </CwgHeader>
        <Columns>
            <>
                <h3>{t(
                    "Existing images",
                    {name: xwg.name, version: xwg.version}
                )}</h3>
                {images.length
                    ? <Table>
                        <Thead>
                            <Tr>
                                <Th className={"td-shrink"}>{t("Image")}</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {images.map(image => <Tr key={image.id}>
                                <Td className={"td-shrink"} verticalAlign={"top"}>
                                    <Box w={"7em"}>
                                        <chakra.img
                                            src={image.url}
                                            style={{width: "7em"}}
                                            alt={image.name}
                                            clipPath={(xwg.overlay && image.type !== "private") ? theme.overlay : undefined}
                                        />
                                    </Box>
                                </Td>
                                <Td verticalAlign={"top"}>
                                    <Dl mb={4}>
                                        <Dt>{t("Name:")}</Dt>
                                        <Dd>{image.name}</Dd>

                                        {image.user && <>
                                            <Dt>{t("Uploaded by:")}</Dt>
                                            <Dd><Trans ns={"cwg_ops"}><ProfileLink user={image.user} /> at <FormattedDate date={image.created} /></Trans></Dd>
                                        </>}

                                        <Dt>{t("Image type:")}</Dt>
                                        <Dd>{getImageBadge(image, t)}</Dd>
                                    </Dl>

                                    <Box textAlign={"right"}>
                                        <ButtonGroup isAttached variant={"secondary"} size={"sm"}>
                                            {cu?.has(TokenScope.CWG_IMAGES) && (image.type !== "primary" || image.custom) && <>
                                                {image.type !== "primary" && <Button
                                                    onClick={async () => {
                                                        await api.post(`/api/v2/xwg/${xwg.id}/image/${image.id}`, {
                                                            type: "primary"
                                                        });
                                                        await reloadImages();
                                                    }}
                                                >
                                                    {t("Set as primary")}
                                                </Button>}
                                                {image.type !== "other" && <Button
                                                    onClick={async () => {
                                                        await api.post(`/api/v2/xwg/${xwg.id}/image/${image.id}`, {
                                                            type: "other"
                                                        });
                                                        await reloadImages();
                                                    }}
                                                >
                                                    {t("Set as secondary")}
                                                </Button>}
                                                {image.type !== "private" && <Button
                                                    onClick={async () => {
                                                        await api.post(`/api/v2/xwg/${xwg.id}/image/${image.id}`, {
                                                            type: "private"
                                                        });
                                                        await reloadImages();
                                                    }}
                                                >
                                                    {t("Set as private")}
                                                </Button>}
                                            </>}
                                            {(image.type !== "primary" || image.custom) && <RemoveButton
                                                confirm={t("Are you sure you want to delete this image?")}
                                                onClick={async () => {
                                                    await api.delete(`/api/v2/xwg/${xwg.id}/image/${image.id}`);
                                                    await reloadImages();
                                                }}
                                            />}
                                        </ButtonGroup>
                                    </Box>
                                </Td>
                            </Tr>)}
                        </Tbody>
                    </Table>
                : <Alert status={"info"}>
                    <AlertIcon />
                    <AlertDescription>{t("There are no additional images for this xWG.")}</AlertDescription>
                </Alert>}
            </>
            <>
                <h3>{t("Upload new image")}</h3>
                <Box mx={"auto"} ml={"auto"} mr={"auto"} maxW={"20em"}>
                    <chakra.form
                        onSubmit={handleSubmit(uploadImage)}
                    >
                        {cu?.has(TokenScope.CWG_IMAGES) && <FormControl>
                            <FormLabel>{t("Type:")}</FormLabel>
                            <Controller
                                control={control}
                                name={"type"}
                                render={({field}) => <Select
                                    instanceId={"type"}
                                    name={field.name}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={[
                                        {value: "primary", label: t("Primary")},
                                        {value: "other", label: t("Secondary")},
                                        {value: "private", label: t("Private")},
                                    ]}
                                />}
                            />
                        </FormControl>}
                        <FormProvider {...methods}>
                            <FormLabel>{t("Image file:")}</FormLabel>
                            <SingleCwgImageUpload
                                overlay={xwg.overlay}
                                {...register("image", {
                                    required: t("You must select image to upload.")
                                })}
                            />
                        </FormProvider>
                        <FormButtons>
                            <Button type={"submit"}>{t("Upload")}</Button>
                        </FormButtons>
                    </chakra.form>
                </Box>
            </>
        </Columns>
    </>;
}
