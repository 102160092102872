import {TanStackRouterDevtools} from '@tanstack/router-devtools'
import * as React from 'react'
import {useCallback, useEffect, useRef} from 'react'
import {createRootRouteWithContext, Outlet, ScrollRestoration, useMatches, useRouter,} from '@tanstack/react-router'
import {Body, Head, Meta, Scripts} from '@tanstack/start'
import {RouterContext} from '../models/routerContext'
import {v4 as uuidv4} from 'uuid';
import {useTranslation} from "../utils/helpers";

import theme from "../style/cwg";
import {NotFound, RootErrorComponent} from "../components/error";
import {ChakraProvider, ToastId, useToast} from "@chakra-ui/react";

import appleTouchIcon from "../assets/static/apple-touch-icon.png?url";
import favicon32x32 from "../assets/static/favicon-32x32.png?url";
import favicon16x16 from "../assets/static/favicon-16x16.png?url";
import siteWebmanifest from "../assets/static/site.webmanifest?url";
import safariPinnedTab from "../assets/static/safari-pinned-tab.svg";
import favicon from "../assets/static/favicon.ico?url";
import browserconfig from "../assets/static/browserconfig.xml?url";

import {AlertProvider} from "../components/generic/alert";
import {getFixedT} from "../utils/getFixedT";
import pino from "pino";
import {Languages} from "../i18n";
import {Announcement} from "../components/generic/announcement";
import _ from "lodash";


export function Html({ children, ...props }: React.HTMLProps<HTMLHtmlElement>) {
  const router = useRouter()

  // warning(
  //   !Object.keys(props).length,
  //   'Passing props other than children to the Html component will be supported very soon in React 19.',
  // )

  if (!router.isServer) {
    return <>{children}</>
  }

  return <html {...props}>{children}</html>
}

export const Route = createRootRouteWithContext<RouterContext>()({
    meta: () => {
        const t = getFixedT("layout");
        return [
            {
                charSet: 'utf-8',
            },
            {
                title: t("CWG Collection"),
            },
            {
                name: "viewport",
                content: "width=device-width, initial-scale=1"
            },
            {
                name: "apple-mobile-web-app-title",
                content: t("CWG Collection"),
            },
            {
                name: "application-name",
                content: "CWG Collection",
            },
            {
                name: "msapplication-TitleColor",
                content: "#f8ecd9",
            },
            {
                name: "msapplication-config",
                content: browserconfig,
            },
            {
                name: "theme-color",
                content: "#f8ecd9",
            },
            {
                name: "description",
                content: t("Website for cataloging the CWG (Wood Geocoins) collection. CWG catalogue.")
            },
            {
                name: "keywords",
                content: "cwg,gcm,katalog,kolekce,sbírka,geocaching,czech wood geocoin,czech,wood,geocoin"
            },
            {
                name: "author",
                content: "Michal 'Niximor' Kuchta <niximor@gmail.com>"
            },
        ]
    },
    links: () => [
        {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: appleTouchIcon,
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: favicon32x32,
        },
        {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: favicon16x16,
        },
        {
            rel: "manifest",
            href: siteWebmanifest,
        },
        {
            rel: "mask-icon",
            href: safariPinnedTab,
            color: "#F8ECD9",
        },
        {
            rel: "shortcut icon",
            href: favicon,
        },
        {
            rel: "preconnect",
            href: "https://img.cwg-kolekce.eu",
        },
        // @ts-ignore
        ...globalThis?.links ?? [],
    ],
    scripts: () => {
        // @ts-ignore
        return (globalThis?.scripts) ?? [];
    },
    component: RootComponent,
    errorComponent: RootErrorComponent,
    notFoundComponent: NotFound,
    beforeLoad: async ({context: {request, ...context}}) => {
        let grid;
        if (context.currentUser.currentUser) {
            grid = `${uuidv4()}:cwg-ui:client:${context.currentUser.currentUser.id}`;
        } else {
            grid = `${uuidv4()}:cwg-ui:client`;
        }

        request.log = pino({
            mixin: () => ({
                id: grid
            })
        });

        request.grid = grid;

        return {
            grid,
        };
    },
    loader: ({context}) => ({
        request: _.omit(context.request, ["log", "i18n"]),
        currentUser: context.currentUser,
        applicationData: context.applicationData,
        grid: context.grid,
    }),
});


function RootComponent() {
    const {i18n, t} = useTranslation();

    const {applicationData} = Route.useLoaderData() as RouterContext;
    const router = useRouter();
    const toast = useToast();
    const loaderRef = useRef<ToastId | null>(null);
    const matches = useMatches();

    const isAllResolved = useCallback(() => {
        return matches.every(match => match.status !== "pending");
    }, [matches]);

    useEffect(() => {
        const unsubscribeBeforeLoad = router.subscribe("onBeforeLoad", () => {
            if (!loaderRef.current) {
                loaderRef.current = toast({
                    duration: null,
                    title: t("Loading data..."),
                    status: "loading",
                    position: "top",
                });
            }
        });

        const unsubscribeOnLoad = router.subscribe("onLoad", () => {
            if (loaderRef.current && isAllResolved()) {
                toast.close(loaderRef.current);
                loaderRef.current = null;
            }
        });

        const unsubscribeOnResolved = router.subscribe("onResolved", () => {
            if (loaderRef.current && isAllResolved()) {
                toast.close(loaderRef.current);
                loaderRef.current = null;
            }
        });

        return () => {
            unsubscribeBeforeLoad();
            unsubscribeOnLoad();
            unsubscribeOnResolved();

            if (loaderRef.current) {
                toast.close(loaderRef.current);
                loaderRef.current = null;
            }
        }
    }, []);

    const fallbackLanguageCode = Languages[i18n.options.fallbackLng as keyof typeof Languages]?.be;
    const currentLanguageCode = Languages[i18n.language as keyof typeof Languages]?.be ?? fallbackLanguageCode;

    return (
        <Html lang={i18n.language}>
            <Head>
                <Meta/>
            </Head>
            <Body>
                {applicationData.environment.toLowerCase() !== "production" && <TanStackRouterDevtools position="bottom-right" />}
                <ChakraProvider theme={theme} toastOptions={{defaultOptions: { position: "top" }}}>
                    <AlertProvider>
                        {(applicationData.announcements ?? []).map((announcement, index) => {
                            const announcementText = (typeof(announcement.text) === "string")
                                ? announcement.text
                                : announcement.text[currentLanguageCode] ?? announcement.text[fallbackLanguageCode];

                            if (!announcementText) {
                                return null;
                            }

                            let colorScheme = "gray";

                            switch (announcement.type) {
                                case "info":
                                    colorScheme = "blue";
                                    break;

                                case "warning":
                                    colorScheme = "yellow";
                                    break;

                                case "error":
                                    colorScheme = "red";
                                    break;
                            }

                            return <Announcement colorScheme={colorScheme} key={index}>{announcementText}</Announcement>;
                        })}
                        <Outlet />
                    </AlertProvider>
                </ChakraProvider>
                <Scripts />
                <ScrollRestoration />
            </Body>
        </Html>
    )
}
