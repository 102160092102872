import {Image, XwgImageType} from "../../models/xwg";
import React, {useState} from "react";
import {Box, BoxProps, chakra, useTheme, VStack} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";

type CwgImageProps = {
    images?: Image[];
    overlay?: boolean;
    showAllStacked?: boolean;
}

const skeletonLoading = keyframes`
    0% {
        background-color: hsla(200, 20%, 80%, .5);
    }
    100% {
        background-color: hsla(200, 20%, 95%, .5);
    }
`;

const ImagePlaceholder = () => {
    const theme = useTheme();

    return <chakra.span
        display={"inline-block"}
        width={"100%"}
        aspectRatio={"1/1"}
        animation={`${skeletonLoading} 1s linear infinite alternate`}
        clipPath={theme.overlay}
    />
}

export function CwgImage({
    images,
    overlay,
    showAllStacked,
    ...props
}: BoxProps & CwgImageProps) {
    const [type, setType] = useState<XwgImageType>("primary");

    const image = images && (images.find(image => image.type === type) ?? images.find(image => image.type === "primary") ?? images[0]);

    const theme = useTheme();

    return <Box
        alignSelf={"flex-start"}
        aspectRatio={"1/1"}
        {...props}
    >
        {images
            ? (showAllStacked
                ? <VStack gap={4}>
                    {images.filter(image => ["primary", "other"].includes(image.type)).map((image, index) => <chakra.img
                        src={image.url}
                        key={index}
                        width={"100%"}
                        aspectRatio={"1/1"}
                        clipPath={overlay || overlay === undefined ? theme.overlay : undefined}
                    />)}
                </VStack>
                : <chakra.img
                    src={image?.url}
                    width={"100%"}
                    aspectRatio={"1/1"}
                    clipPath={overlay || overlay === undefined ? theme.overlay : undefined}
                    onMouseEnter={() => setType("other")}
                    onMouseLeave={() => setType("primary")}
                />
            )
            : <ImagePlaceholder/>
        }
    </Box>
}
